import React, { ReactNode } from "react";
import { Col, Image, Row, Modal, Spinner, ListGroup } from "react-bootstrap";
import styled from "styled-components";

import MetadataCarousel from "./MetadataCarousel";
import * as utils from "../utils/Utils";
import { getAssociations, getMLT } from "../utils/Requests";
import { parse as is08601parse } from "iso8601-duration";
import Logger from "../utils/Logger";

const MLTRow = styled(Row)`
    border-top: gray thin solid;
`;

const SlimGroupItem = styled(ListGroup.Item)`
    padding: 3px 5px;
`;

const EntityItem = styled(SlimGroupItem)`
    text-transform: capitalize;
`;

const ItemImage = styled(Image)`
    max-height: 230px;
`;

type MetadataModalProperties = {
    item: Record<string, any>
    customer?: CustomerConfig,
    showModal: boolean,
    closeCallback: () => void,
    username: string,
    groups: string[]
}

type MetadataModalState = {
    mltItems: Array<Record<string, any>>,
    mltReturned: boolean,
    entityAssociations: EntityAssociation[],
}

export default class MetadataModal extends React.Component<MetadataModalProperties, MetadataModalState> {

    constructor(props: MetadataModalProperties) {
        super(props);

        this.state = {
            mltItems: [],
            entityAssociations: [],
            mltReturned: false
        };
    }

    public componentDidUpdate(prevProps: MetadataModalProperties): void {
        if (prevProps.item["id"] !== this.props.item["id"] && this.props.item["id"] !== undefined) {
            this.getMLTItems();
            this.getEntityAssociations();
        } else if (prevProps.item["id"] !== this.props.item["id"] && this.props.item["id"] === undefined) {
            this.setState({
                mltItems: [],
                entityAssociations: []
            });
        }
    }

    private async getMLTItems(): Promise<void> {
        this.setState({
            mltItems: [],
            mltReturned: false
        });

        if (this.props.customer) {
            const mltItems = await getMLT(this.props.customer, this.props.item, this.props.username, this.props.groups);
            this.setState({
                mltItems,
                mltReturned: true
            });
        }
    }

    private async getEntityAssociations(): Promise<void> {
        if (this.props.customer) {
            await getAssociations(this.props.customer, this.props.item["id"]).then(response => {
                return response.json();
            }).then(jsonOutput => {
                if (Array.isArray(jsonOutput)) {
                    this.setState({
                        entityAssociations: jsonOutput
                    });
                } else {
                    this.setState({
                        entityAssociations: jsonOutput
                    });
                }
            }).catch(error => {
                Logger.error("Error getting entity associations: ", error);
            });
        }
    }

    private createEntityAssociationItems(): ReactNode {
        const tags = new Array<EntityAssociation>();
        const people = new Array<EntityAssociation>();
        const genres = new Array<EntityAssociation>();
        const franchises = new Array<EntityAssociation>();
        const autoFranchises = new Array<EntityAssociation>();

        for (const association of this.state.entityAssociations) {

            switch (association.entityType.toLowerCase()) {
                case "tag": tags.push(association);
                    break;
                case "people": people.push(association);
                    break;
                case "genre": genres.push(association);
                    break;
                case "franchise": franchises.push(association);
                    break;
                case "autofranchise": autoFranchises.push(association);
                    break;
            }
        }

        return (
            <>
                {tags.length > 0 && <EntityItem><b>Tags</b>: {tags.map((item) => { return item.entityName; }).join(", ")}</EntityItem>}
                {people.length > 0 && <EntityItem><b>People</b>: {people.map((item) => { return item.entityName; }).join(", ")}</EntityItem>}
                {genres.length > 0 && <EntityItem><b>Genres</b>: {genres.map((item) => { return item.entityName; }).join(", ")}</EntityItem>}
                {franchises.length > 0 && <EntityItem><b>Franchise</b>: {franchises.map((item) => { return item.entityName; }).join(", ")}</EntityItem>}
                {autoFranchises.length > 0 && <EntityItem><b>Auto Franchise</b>: {autoFranchises.map((item) => { return item.entityName; }).join(", ")}</EntityItem>}
            </>
        );
    }

    public render(): ReactNode {
        const item = this.props.item;

        let description = item["description"];
        if (description) description = `<b>Description</b>: ${description}`;
        const genres = utils.getGenres(item).join(", ");
        const rating = utils.getContentRating(item);
        const director = utils.getDirector(item["director"]).join(", ");
        const producers = utils.getAllProducers(item["producer"]).join(", ");
        const actors = utils.getActors(item["actor"]).join(", ");
        const releaseYear = utils.getDatePublished(item);
        const videoQuality = utils.getVideoQuality(item);
        const schedulerChannel = utils.getSchedulerChannel(item);
        let mins = "";

        if ("duration" in Object.keys(item)) {
            const duration = item["duration"];

            if (duration) {
                const parsedDuration = is08601parse(duration);
                mins = parsedDuration.hours * 60 + parsedDuration.minutes;
            }
        }

        return (
            <Modal
                show={this.props.showModal}
                centered
                backdrop="static"
                dialogClassName="modal-80w"
                onHide={this.props.closeCallback}
                bsPrefix="metadata-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{item["name"]}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={3} style={{ textAlign: "center" }}>
                            <ItemImage
                                src={utils.getImageUrl(item)}
                                className="img-fluid"
                                alt={item["id"]}
                                onError={(event) => { utils.getPlaceholderImage(event, item); }}
                                rounded
                            />
                            <br />
                            <p><b>ID:</b> {item["id"]}
                                {schedulerChannel && <><br /><b>Scheduler Channel:</b> {schedulerChannel}</>}
                            </p>
                        </Col>
                        <Col md={5}>
                            <ListGroup variant="flush">
                                {description && <SlimGroupItem dangerouslySetInnerHTML={{
                                    __html: description
                                }}></SlimGroupItem>}
                                {releaseYear !== "Unknown" && <SlimGroupItem><b>Release Year</b>: {releaseYear}</SlimGroupItem>}
                                {mins && <SlimGroupItem><b>Duration</b>: {mins}mins</SlimGroupItem>}
                            </ListGroup>
                        </Col>
                        <Col md={4}>
                            <ListGroup variant="flush">
                                {genres && <SlimGroupItem><b>Genres</b>: {genres}</SlimGroupItem>}
                                {rating !== "Unknown" && <SlimGroupItem><b>Rating</b>: {rating}</SlimGroupItem>}
                                {director && <SlimGroupItem><b>Director</b>: {director}</SlimGroupItem>}
                                {actors && <SlimGroupItem><b>Actors</b>: {actors}</SlimGroupItem>}
                                {producers && <SlimGroupItem><b>Producers</b>: {producers}</SlimGroupItem>}
                                {videoQuality && <SlimGroupItem><b>Video Quality</b>: {videoQuality}</SlimGroupItem>}
                                {this.state.entityAssociations && this.createEntityAssociationItems()}
                            </ListGroup>
                        </Col>
                    </Row>
                    <MLTRow>
                        <Col style={{ textAlign: "center" }}>
                            <h5 className="mx-auto">More Like This</h5>
                            {this.state.mltItems.length > 0 &&
                                <MetadataCarousel
                                    items={this.state.mltItems}
                                    customer={this.props.customer}
                                    username={this.props.username}
                                    groups={this.props.groups}
                                    lessItems
                                    openInNewTab
                                />
                            }
                            {this.state.mltItems.length === 0 && !this.state.mltReturned &&
                                <Spinner animation={"border"} variant={"secondary"} />
                            }
                        </Col>
                    </MLTRow>
                </Modal.Body>
            </Modal>
        );
    }
}
