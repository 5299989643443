import React, { ReactNode } from "react";
import { Modal, Spinner, Row, Table } from "react-bootstrap";
import styled from "styled-components";
import Logger from "../../utils/Logger";
import { getAssociations } from "../../utils/Requests";
import { getCustomerConfig } from "../../config/Customers";

const SpinnerDiv = styled.div`
    position: absolute;
    top: 39%;
    right: 50%;
    z-index: 10;
`;

type EntityAssociationModalState = {
    entityAssociations: EntityAssociation[],
    loading?: boolean,
    refresh: boolean
    showSuccess?: boolean,
    showError?: boolean,
    error?: Error,
    errorMsg?: string
}

type EntityAssociationModalProperties = {
    customer: string,
    item: string,
    closeCallback: () => void,
    show?: boolean
}

export default class CreateNewEntityModal extends React.Component<EntityAssociationModalProperties, EntityAssociationModalState> {

    constructor(props: EntityAssociationModalProperties) {
        super(props);

        this.state = {
            entityAssociations: new Array<EntityAssociation>(),
            refresh: false,
            showSuccess: false,
            showError: false,
            errorMsg: ""
        };
    }

    private renderAssociationResults(): ReactNode {
        return <Row>
            <Table striped bordered responsive size="sm">
                <thead>
                    <tr>
                        <th>Entity Name</th>
                        <th>Entity Type</th>
                        <th>Weight</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.entityAssociations.map(row => (
                        <tr key={row.id}>
                            <td key={row.id + "name"}>
                                {<a href={`entitiesDetail?entityId=${row["entityId"]}`} target="_blank" rel="noreferrer" >{row.entityName}</a>
}                            </td>
                            <td key={row.id + "type"}>
                                {row.entityType.replaceAll("_", " ")}
                            </td>
                            <td key={row.id + "weight"}>
                                {row.entityWeight}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Row>;
    }

    // Commented out for support editing weights
    // private saveEntityWeights = (): void => {
    //     this.setState({
    //     refresh: true
    // });
    //     this.postEntityAssociations();
    // }

    // private resetEntityWeights = (): void  => {
    //     this.setState({
    //         refresh: true
    //     });
    //     this.getEntityAssociations();
    // }

    private startRefresh(): void {
        this.setState({
            refresh: false
        });
        this.props.closeCallback();

        setTimeout(() => {
            this.setState({
                showSuccess: false
            });
        }, 2500);
    }

    // Commented out for support editing weights
    // private closeSuccess = (): void => {
    //     this.setState({
    //         showSuccess: false
    //     });
    // }

    // private closeError = (): void => {
    //     this.setState({
    //         showError: false
    //     });
    // }


    // Commented out for support editing weights
    // private async postEntityAssociations(): Promise<void> {
    //     if (this.props.customer) {
    //         const postResponse = await postAssociations(getCustomerConfig(this.props.customer), this.state.entityAssociations).catch(error => {
    //             Logger.error("Error posting entity associations: ", error);
    //             this.setState({
    //                 showError: true,
    //                 refresh: false
    //             });
    //         });
    //         if (postResponse) {
    //             this.setState({
    //                 showSuccess: true
    //             },
    //             this.startRefresh
    //             );
    //         } else {
    //             this.setState({
    //                 showError: true,
    //                 refresh: false
    //             });
    //         }
    //     }
    // }

    private async getEntityAssociations(): Promise<void> {
        if (this.props.customer) {
            await getAssociations(getCustomerConfig(this.props.customer), this.props.item).then(response => {
                return response.json();
            }).then(jsonOutput => {
                if (Array.isArray(jsonOutput)) {
                    this.setState({
                        entityAssociations: jsonOutput
                    });
                } else {
                    this.setState({
                        entityAssociations: jsonOutput
                    });
                }
            }).catch(error => {
                Logger.error("Error getting entity associations: ", error);
            });
            this.setState({
                refresh: false
            });
        }
    }

    public render(): ReactNode {
        return (
            <>
                {this.state.refresh &&
                <SpinnerDiv>
                    <Spinner animation="border" style={{color: "white"}}/>
                </SpinnerDiv>}

                <Modal
                    show={this.props.show}
                    onHide={()=> { this.props.closeCallback(); }}
                    onShow={()=> { this.getEntityAssociations(); }}
                    centered
                    dialogClassName="modal-70w"
                >

                    <Modal.Header closeButton>
                        <Modal.Title>Association Weights</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div style={{opacity: this.state.refresh ? 0.5 : 1}}>
                            {this.renderAssociationResults()}
                        </div>
                    </Modal.Body>


                    {/*
                    // Commented out for support editing weights
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.resetEntityWeights}>
                            Reset
                        </Button>
                        <Button variant="primary" onClick={this.saveEntityWeights}>
                            Save
                        </Button>
                    </Modal.Footer> */}

                </Modal>

                {/*
                // Commented out for support editing weights
                <AssocAlert
                    variant='success'
                    show={this.state.showSuccess}
                    onClose={this.closeSuccess}
                    dismissible
                >
                    Associations saved
                </AssocAlert>
                <AssocAlert
                    variant='danger'
                    show={this.state.showError}
                    onClose={this.closeError}
                    dismissible
                >
                    Error saving associations
                </AssocAlert> */}

            </>
        );
    }
}