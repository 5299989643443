import React, { ReactNode } from "react";
import { Button, Col, Container, Form, Image, InputGroup, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap";
import { Bookmark as PresetIcon } from "react-bootstrap-icons";
import styled from "styled-components";

import Branding from "../config/Branding";
import { SearchResults } from "../components/SearchResults";
import { getAvailablePresets } from "../config/Presets";
import { createRequest, getItemMetadata, getMetadataURL, makeSlotRequest } from "../utils/Requests";
import * as utils from "../utils/Utils";
import * as constants from "../constants";
import Logger from "../utils/Logger";
import RandomField from "../components/fields/RandomField";
import SeedAndUserHistoryPanel from "../components/SeedAndUserHistoryPanel";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { CentralCol } from "../components/SharedStyled";

const ImageCol = styled(Col)`
    margin-right: 20px;
`;

const ScaledImage = styled(Image)`
    height: unset;
    width: unset;
    max-width: 250px;
    max-height: 95px;
`;

const FallbackViewerMainRow = styled(Row)`
    background: ${Branding.transparentBackground};
    top: ${props => props.standalone ? "30px" : "63px"};
`;

const FallbackViewerFormRow = styled(Form.Row)`
    margin-top: 10px;
    margin-bottom: 20px;
`;

const FallbackViewerElementRow = styled(Form.Row)`
    margin-bottom: 5px;
`;

const FallbackViewerResultsRow = styled(Row)`
    min-height: 64vh;
`;

const FallbackViewerFormCol = styled(Col)`
    padding-left: 0 !important;
`;

type FallbackViewerState = {
    availablePresets: Preset[],
    preset: Preset,
    userId: string,
    seedId: string | undefined,
    bywSeedId: string | undefined,
    items: Array<Record<string, any>>,
    error: Error | null,
    isLoaded: boolean
    isLoading: boolean,
    presetNumber: number,
    seedItem?: Record<string, any>,
    showSeedAndHistoryPanel: boolean
}

type FallbackViewerProps = {
    standalone?: boolean,
    metadataAccess?: boolean,
    groups: string[],
    username: string
    customerConfig: CustomerConfig
}

interface FallbackViewerProperties extends FallbackViewerProps, RouteComponentProps { }

class FallbackViewer extends React.Component<FallbackViewerProperties, FallbackViewerState> {

    private timeoutId: number | null;

    constructor(props: FallbackViewerProperties) {
        super(props);
        this.timeoutId = null;
        const availablePresets = this.getPresets();

        this.state = {
            availablePresets,
            preset: availablePresets[0],
            presetNumber: 0,
            userId: availablePresets[0].userId,
            seedId: availablePresets[0].seedId,
            bywSeedId: undefined,
            items: [],
            isLoading: false,
            error: null,
            isLoaded: false,
            showSeedAndHistoryPanel: false
        };
    }

    public componentDidMount(): void {
        this.update();
    }

    private getPresets(): Preset[] {
        return getAvailablePresets(this.props.groups, false);
    }

    private update = (): void => {
        this.setState({
            isLoading: true,
            isLoaded: false
        }, () => {
            this.requestItems();
            if (this.state.seedId) this.getSeedInfo();
        });
    }

    private handleError(error: Error) {
        Logger.error(error);
        this.setState({
            isLoading: false,
            isLoaded: true,
            error
        });
    }

    private getUrl = (includeParams = true) => {
        const preset = this.state.preset;
        let url = `${this.props.customerConfig.fallbackHost}/slots/${preset.slotId}/items`;

        if (includeParams) {
            const formattedParameters = utils.getFormattedParameters(preset.parameters);
            url += `?_debug_metadata=true&ignore=testtool&userId=${encodeURIComponent(this.state.userId.trim())}`;
            url += formattedParameters;

            url = utils.addPortalUserInfoToUrl(url, this.props.username, this.props.groups);

            if (this.state.seedId) {
                url += `&seedIds=${this.state.seedId}`;
            }
        }

        return url;
    }

    public async requestItems(): Promise<void> {
        await makeSlotRequest(this.getUrl(true))
            .then((response) => {
                if (response.status !== 200) {
                    if (response.status === 404) {
                        throw new Error("404: Not Found");
                    } else {
                        const contentType = response.headers.get("content-type");
                        if (contentType && contentType.indexOf("application/json") !== -1) {
                            response.json().then((error) => {
                                this.handleError(error);
                            });
                        } else {
                            response.text().then((error) => {
                                this.handleError(new Error(error));
                            });
                        }
                    }
                } else {
                    response.json().then(async (result) => {
                        if (result) {
                            let items = this.prepareItems(result.items);
                            let bywSeedId: string | undefined = "";
                            if (result.modelInfo) {
                                if (result.modelInfo.seed) {
                                    bywSeedId = result.modelInfo.seed.id;
                                }
                            }
                            const metadataRequest = await createRequest(
                                getMetadataURL(this.props.customerConfig),
                                "POST",
                                JSON.stringify(items)
                            );

                            items = await fetch(metadataRequest).then((response) => {
                                if (response.ok) {
                                    return response.json().then((result) => {
                                        return result.items;
                                    });
                                } else {
                                    return Promise.reject(response);
                                }
                            });
                            this.setState({
                                isLoading: false,
                                isLoaded: true,
                                items,
                                error: null,
                                bywSeedId
                            }, this.getSeedInfo);
                        }
                    });
                }
            }).catch((error) => {
                this.handleError(error);
            });
    }

    private prepareItems(items: Array<Record<string, any>>): Array<Record<string, any>> {
        if (this.props.customerConfig.name.toLowerCase() === "uktv") {
            items.forEach((item: Record<string,any>) => {
                if (item["typeName"] === "Brand") {
                    item["id"] = `${item["id"]}_brand`;
                }
            });
        }

        return items;
    }

    private checkKey = (event: React.KeyboardEvent): void => {
        if (event.key === "Enter" || event.key === "NumpadEnter") {
            if (this.timeoutId) {
                clearTimeout(this.timeoutId);
            }
            this.update();
        }
        else if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    }

    private setUserId = (callbackValue: string, submit: boolean): void => {
        this.setState({
            userId: callbackValue
        }, () => {
            if (submit) {
                this.update();
            }
        });
    }

    private setSeedId = (callbackValue: string, submit: boolean): void => {
        this.setState({
            seedId: callbackValue
        }, () => {
            if (submit) {
                this.update();
            }
        });
    }

    private setPresets = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const preset: Preset = this.state.availablePresets[parseInt(event.target.value)];

        this.setState({
            preset,
            presetNumber: parseInt(event.target.value),
            userId: preset.userId,
            seedId: preset.seedId,
            items: [],
            isLoading: false,
            isLoaded: false,
            error: null,
            seedItem: undefined
        }, this.update);
    }

    private renderSlotResults(): ReactNode {
        const { error, isLoading, isLoaded } = this.state;

        if (error) {
            return <Row className="justify-content-center">
                Error - {error.message}
            </Row>;
        }

        if (isLoading) {
            return <Spinner animation="border" variant="primary" />;
        }

        if (!isLoading && !isLoaded) {
            return <Row className="justify-content-center">
                Results will appear here
            </Row>;
        } else {
            return <SearchResults
                customer={this.props.customerConfig}
                showMetadataLink={this.props.metadataAccess}
                items={this.state.items}
                username={this.props.username}
                groups={this.props.groups}
                slotId={this.state.preset.slotId}
                history={this.props.history}
            />;
        }
    }

    private getSeedInfo = async (): Promise<void> => {
        if (this.props.customerConfig && (this.state.seedId || this.state.bywSeedId)) {
            let lookupSeedId: string | undefined = "";
            if (this.state.seedId) {
                lookupSeedId = this.state.seedId;
            } else {
                lookupSeedId = this.state.bywSeedId;
            }

            await getItemMetadata(this.props.customerConfig, lookupSeedId!).then((response) => {
                if (response.ok) {
                    return response.json().then((response) => {
                        this.setState({
                            seedItem: response.items[0]
                        });
                    });
                } else {
                    return Promise.reject(response);
                }
            });
        }
    }

    private toggleSeedAndHistoryPanel = (): void => {
        this.setState({
            showSeedAndHistoryPanel: !this.state.showSeedAndHistoryPanel
        });
    }

    public render(): ReactNode {
        if (this.props.customerConfig.fallbackHost) {
            return (
                <Container className="mw-100">
                    <FallbackViewerMainRow className="justify-content-center sticky-banner" standalone={this.props.standalone ? 1 : 0}>
                        <CentralCol md>
                            <Form className="FallbackViewerSettings">
                                <FallbackViewerFormRow className="justify-content-center">
                                    <ImageCol md={2} className="d-none d-sm-block my-auto">
                                        <Row className="justify-content-center">
                                            {this.props.customerConfig.logoLocation &&
                                            <ScaledImage
                                                src={this.props.customerConfig.logoLocation}
                                                onError={(event) => { utils.getErrorLogo(event); }}
                                            />}
                                        </Row>
                                    </ImageCol>
                                    <Col md={4}>
                                        <FallbackViewerElementRow>
                                            <InputGroup>
                                                <InputGroup.Prepend>
                                                    <OverlayTrigger
                                                        placement="left"
                                                        overlay={<Tooltip id="preset-tooltip">Preset</Tooltip>}
                                                    >
                                                        <InputGroup.Text><PresetIcon /></InputGroup.Text>
                                                    </OverlayTrigger>
                                                </InputGroup.Prepend>
                                                <Form.Control
                                                    as="select"
                                                    id="dropdown-preset"
                                                    title="Preset"
                                                    size="sm"
                                                    onChange={this.setPresets}
                                                    style={{ "padding": "0.375rem 0.375rem 0.375rem 0.1rem" }}
                                                    value={this.state.presetNumber}>
                                                    {this.state.availablePresets.map((preset, i) => {
                                                        return (
                                                            <option value={i} key={i}>
                                                                {preset.display} - FALLBACK
                                                            </option>
                                                        );
                                                    })}

                                                </Form.Control>
                                            </InputGroup>
                                        </FallbackViewerElementRow>
                                        <FallbackViewerElementRow>
                                            <RandomField
                                                customer={this.props.customerConfig}
                                                type={constants.USER}
                                                small
                                                includeIcon
                                                initialValue={this.state.userId}
                                                onChangeCallback={this.setUserId}
                                                onKeyUpCallback={this.checkKey}
                                            />
                                        </FallbackViewerElementRow>
                                        <FallbackViewerElementRow>
                                            <FallbackViewerFormCol md={10}>
                                                <RandomField
                                                    customer={this.props.customerConfig}
                                                    type={constants.SEED}
                                                    includeIcon
                                                    small
                                                    initialValue={this.state.seedId}
                                                    onChangeCallback={this.setSeedId}
                                                    onKeyUpCallback={this.checkKey}
                                                    showName
                                                />
                                            </FallbackViewerFormCol>
                                            <FallbackViewerFormCol md={2} style={{ textAlign: "right", paddingRight: "0px" }}>
                                                <Button variant="primary" size="sm" type="button" onClick={this.update}>
                                                    Submit
                                                </Button>
                                            </FallbackViewerFormCol>
                                        </FallbackViewerElementRow>
                                    </Col>
                                </FallbackViewerFormRow>
                            </Form>
                        </CentralCol>
                    </FallbackViewerMainRow>
                    <FallbackViewerResultsRow className="justify-content-center">
                        <CentralCol md>
                            {this.renderSlotResults()}
                        </CentralCol>
                    </FallbackViewerResultsRow>
                    <SeedAndUserHistoryPanel
                        customerConfig={this.props.customerConfig}
                        userId={this.state.userId}
                        seedItem={this.state.seedItem}
                        showPanel={this.state.showSeedAndHistoryPanel}
                        showHidePanelCallback={this.toggleSeedAndHistoryPanel}
                    />
                </Container>
            );
        } else {
            return (
                <Container className="mw-100">
                    <FallbackViewerMainRow className="justify-content-center sticky-banner" standalone={this.props.standalone ? 1 : 0}>
                        <CentralCol md>No fallback available for this customer, please choose another screen</CentralCol>
                    </FallbackViewerMainRow>
                </Container>
            );
        }
    }
}

export default withRouter(FallbackViewer);