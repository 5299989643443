import React, { ReactElement, ReactNode } from "react";
import { Col, Container, Form, InputGroup, Row, Table } from "react-bootstrap";
import styled from "styled-components";
import Branding from "../config/Branding";
import { getEventBridgeRuleInformation } from "../utils/Requests";

import Logger from "../utils/Logger";
import { getRegions } from "../utils/Utils";

const MainRow = styled(Row)`
    background: ${Branding.transparentBackground};
    top: ${props => props.standalone ? "30px" : "63px"};
    margin: 10px;
`;

type EventBridgeState = {
    rules: Array<Record<string, any>>,
    region: string
}

type EventBridgeProperties = {
    standalone?: boolean,
    groups: string[],
    username: string,
    metadataAccess?: boolean,
    customerConfig: CustomerConfig
}

export default class EventBridge extends React.Component<EventBridgeProperties, EventBridgeState> {

    constructor(props: EventBridgeProperties) {
        super(props);

        this.state = {
            rules: [],
            region: "eu-west-2"
        };
    }

    public componentDidMount(): void {
        if (this.state.rules.length === 0) {
            this.refresh();
        }
    }

    private refresh = (): void => {
        this.setState({
            rules: []
        });


        this.getEventBridgeRules();
    }

    private async getEventBridgeRules(): Promise<void> {
        await getEventBridgeRuleInformation(this.state.region).then((response: Record<string, any>) => {
            this.setState({
                rules: response["rules"]
            });
        }).catch(error => {
            Logger.error("Error getting API health: ", error);
        });
    }

    private populateEventBridgeRules(): ReactNode {
        const rulesByCustomer: Record<string, any> = {};
        let customerNames: Array<string> = [];
        const jobNames: Array<string> = [];

        this.state.rules.forEach((rule: Record<string, any>) => {
            const customer = rule["Customer"];
            const jobName = rule["JobName"];

            if (Object.keys(rulesByCustomer).includes(customer)) {
                rulesByCustomer[customer].push(rule);
            } else {
                rulesByCustomer[customer] = [rule];
            }

            if (customerNames.indexOf(customer) === -1) {
                customerNames.push(customer);
            }

            if (jobNames.indexOf(jobName) === -1) {
                jobNames.push(jobName);
            }
        });

        customerNames = customerNames.filter((value: string) => { return value !== "operational"; }).sort();
        customerNames.push("operational");

        const output: Record<string, Record<string,string>> = {};
        // We skip the AWS 'customer' and any job without a defined name
        customerNames.filter((job: string) => { return job !== "AWS";}).forEach((customer: string) => {
            jobNames.filter((job: string) => { return job !== "N/A";}).forEach((job: string) => {
                let state = "UNKNOWN";
                rulesByCustomer[customer].forEach((rule: Record<string, any>) => {
                    if (rule["JobName"] === job) {
                        if (rule["State"] === "ENABLED") {
                            state = "ENABLED";
                        } else if (rule["State"] === "DISABLED") {
                            state = "DISABLED";
                        }
                    }
                });
                if (Object.keys(output).indexOf(job) > -1) {
                    output[job][customer] = state;
                } else {
                    output[job] = {};
                    output[job][customer] = state;
                }
            });
        });

        const htmlOutput: Array<ReactElement> = [];

        const nameRowCells = [<td></td>];
        customerNames.filter((customer: string) => { return customer !== "AWS";}).forEach((name)=> {
            nameRowCells.push(<td style={{verticalAlign: "bottom"}}><span style={{transform: "rotate(180deg)", writingMode: "vertical-rl"}}><b>{name}</b></span></td>);
        });

        htmlOutput.push(
            <tr>
                {nameRowCells}
            </tr>
        );

        Object.keys(output).forEach((job: string) => {
            const jobMapping= Object.keys(output[job]).flatMap((customer: string) => {
                const active = output[job][customer] === "ENABLED";
                const inactive = output[job][customer] === "DISABLED";
                return <td style={{background: active ? "green" : "", color: "white"}}>{active ? <>&#10003;</> : inactive ? <>&#10007;</> : <></>}</td>;
            });
            htmlOutput.push(
                <tr>
                    <td>{job}</td>
                    {jobMapping}
                </tr>
            );
        });

        return <>{htmlOutput}</>;
    }

    private setRegion = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            region: event.currentTarget.value
        }, this.refresh);
    }

    public render(): ReactNode {
        return (
            <div>
                <Container className="mw-100" key="central">
                    <MainRow className="justify-content-center sticky-banner" standalone={this.props.standalone ? 1 : 0}>
                        <Col md>
                            <Row>
                                <Col style={{textAlign: "center", padding: "5px", alignContent: "center"}}>
                                    <h5>EventBridge Rules</h5>
                                    <Row style= {{width: "fit-content"}}>
                                        <InputGroup>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text>Region</InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control
                                                as="select"
                                                id="dropdown-preset"
                                                title="Preset"
                                                size="sm"
                                                onChange={this.setRegion}
                                                style={{ "padding": "0.375rem 0.375rem 0.375rem 0.1rem" }}
                                                value={this.state.region}>
                                                {getRegions().map((region, i) => {
                                                    return (
                                                        <option value={region} key={i}>
                                                            {region}
                                                        </option>
                                                    );
                                                })}

                                            </Form.Control>
                                        </InputGroup>
                                    </Row>
                                    <Row className="justify-content-center">
                                        <Table striped bordered size="sm" style={{width: "auto"}}>
                                            {this.state.rules.length > 0 && <tbody>
                                                {this.populateEventBridgeRules()}
                                            </tbody>}
                                        </Table>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </MainRow>
                </Container>
            </div>
        );
    }
}