import React, { ReactNode } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import styled from "styled-components";
import {
    ChevronDoubleDown as HideIcon,
    ChevronDoubleUp as ShowIcon
} from "react-bootstrap-icons";

import HistoryCarousel from "./lanebuilder/HistoryCarousel";
import SeedInfo from "./SeedInfo";
import { getUserHistory } from "../utils/Requests";
import Logger from "../utils/Logger";
import Branding from "../config/Branding";
import { CentralCol } from "../components/SharedStyled";

const ToggleButton = styled(Button)`
    position: absolute;
    z-index: 100;
    height: 30px;
    width: 30px;
    padding: 0;
    left: 15px;
    top: -15px;
`;

const HeaderRow = styled(Row)`
    padding-top: 5px;
    padding-bottom: 5px;
`;

const StickyBottomRow = styled(Row)`
    background: ${Branding.black};
    position: sticky;
    bottom: 0px;
    z-index: 200;
    border-top: thin solid gray;
    padding-bottom: 10px;
`;

const SpinnerDiv = styled.div`
    position: absolute;
    top: 39%;
    right: 50%;
    z-index: 10;
`;

type SeedAndUserHistoryPanelProperties = {
    customerConfig: CustomerConfig,
    userId: string,
    seedItem?: Record<string, any>,
    showPanel: boolean,
    showHidePanelCallback: () => void
}

type SeedAndUserHistoryPanelState = {
    userHistory: Array<Record<string, any>>,
    loading: boolean,
    loaded: boolean,
    brandView: boolean
}

export default class SeedAndUserHistoryPanel extends React.Component<SeedAndUserHistoryPanelProperties, SeedAndUserHistoryPanelState> {

    constructor(props: SeedAndUserHistoryPanelProperties) {
        super(props);

        this.state = {
            userHistory: [],
            loading: false,
            loaded: false,
            brandView: true
        };
    }

    public componentDidMount(): void {
        if (this.props.userId !== undefined && this.props.userId.length > 0) {
            this.requestUserHistory();
        }
    }

    public componentDidUpdate(prevProps: Readonly<SeedAndUserHistoryPanelProperties>): void {
        if (this.props.userId !== undefined && this.props.userId.length > 0 && prevProps.userId !== this.props.userId) {
            this.requestUserHistory();
        }
    }

    private async requestUserHistory(): Promise<void> {
        this.setState({
            loading: true,
            loaded: false,
            userHistory: []
        });

        await getUserHistory(this.props.customerConfig!, this.props.userId, this.state.brandView).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                this.setState({
                    userHistory: [],
                    loading: false,
                    loaded: true
                });
            }
        }).then(jsonOutput => {
            let historyItems = [];
            historyItems = jsonOutput.history;

            this.setState({
                userHistory: historyItems,
                loading: false,
                loaded: true
            });
        }).catch(error => {
            this.setState({
                userHistory: [],
                loading: false,
                loaded: true
            }, () => Logger.log(error));
        });
    }

    private changeView(): void {
        this.setState({
            brandView: !this.state.brandView
        }, this.requestUserHistory);
    }

    public render(): ReactNode {
        const height = this.props.showPanel ? "auto" : "35px";
        const minHeight =  this.props.showPanel ? "270px" : "35px";

        return (
            <StickyBottomRow style={{height, minHeight}}>
                <ToggleButton onClick={this.props.showHidePanelCallback}>
                    {this.props.showPanel ? <HideIcon /> : <ShowIcon />}
                </ToggleButton>
                {this.props.showPanel ?
                    <>
                        <>
                        <CentralCol md={2}>
                            <HeaderRow className="justify-content-center">
                                <big><b>Seed</b></big>
                            </HeaderRow>
                            {this.props.seedItem ?
                                <SeedInfo customerConfig={this.props.customerConfig} seedItem={this.props.seedItem} hideBody />
                                :
                                <p>No Seed selected</p>
                            }
                        </CentralCol>
                        <CentralCol md={10}>
                            <Row>
                                <Col md={2} className="ml-auto" style={{paddingTop: "5px", paddingBottom: "5px"}}>
                                    <Form.Switch
                                        className="brandSwitch"
                                        onChange={() => {this.changeView();}}
                                        id="brand-switch"
                                        checked={this.state.brandView}
                                        label={this.state.brandView ? "Brand View" : "Episode View"}
                                    />
                                </Col>
                                <Col>
                                    <HeaderRow className="justify-content-center">
                                        <big><b>User History</b></big>
                                    </HeaderRow>
                                </Col>
                            </Row>
                            {this.state.loading ?
                                <>
                                <SpinnerDiv>
                                    <Spinner animation="border" />
                                </SpinnerDiv>
                                </>
                                :
                                    <Row className="justify-content-center">
                                        <Col>
                                        {this.state.userHistory.length > 0 &&
                                            <HistoryCarousel
                                                items={this.state.userHistory}
                                                loading={this.state.loading}
                                                loaded={this.state.loaded}
                                                brandView={this.state.brandView}
                                            />
                                        }
                                        {this.state.userHistory.length === 0 && this.state.loaded &&
                                            <p>No User History found</p>
                                            }
                                        </Col>
                                    </Row>
                                }
                        </CentralCol>
                        </>
                    </>
                    :
                    <CentralCol>
                        <Button variant="link" onClick={this.props.showHidePanelCallback}>Show seed information & user history</Button>
                    </CentralCol>
                }
            </StickyBottomRow>
        );
    }
}
