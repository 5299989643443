import React, { ReactNode } from "react";
import { Button, InputGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Search as SearchIcon, Trash as ClearIcon } from "react-bootstrap-icons";
import { FieldValue, FormEntry } from "../SharedStyled";

type SearchFromState = {
    searchTerm: string
}

type SearchFormProperties = {
    searchCallback: (arg0: string) => void,
    resetCallback: () => void
}

export default class SearchForm extends React.Component<SearchFormProperties, SearchFromState> {

    private timeoutId: NodeJS.Timeout | null;

    constructor(props: SearchFormProperties) {
        super(props);

        this.timeoutId = null;

        this.state = {
            searchTerm: ""
        };
    }

    private search = () => {
        this.props.searchCallback(this.state.searchTerm);
    }

    public reset = (): void => {
        this.setState({
            searchTerm: ""
        }, this.props.resetCallback);
    }

    private setSearchTerm = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            searchTerm: event.currentTarget.value
        });
    }

    private checkSearchKey = (event: React.KeyboardEvent): void => {
        if (event.key === "Enter" || event.key === "NumpadEnter") {
            if (this.timeoutId) {
                clearTimeout(this.timeoutId);
            }
            this.search();
        }
        else if (this.state.searchTerm.length >= 1) {
            if (this.timeoutId) {
                clearTimeout(this.timeoutId);
            }
            this.timeoutId = setTimeout(this.search, 500);
        }
        else if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    }

    private checkBackspaceKey = (event: React.KeyboardEvent): void => {
        if ((event.key === "Backspace" || event.key === "Delete") && this.state.searchTerm.length >= 2) {
            if (this.timeoutId) {
                clearTimeout(this.timeoutId);
            }
            this.timeoutId = setTimeout(this.search, 500);
        }
    }

    public render(): ReactNode {
        return (
            <FormEntry>
                <InputGroup>
                    <FieldValue
                        id="searchInput"
                        type="string"
                        placeholder="Search..."
                        onChange={this.setSearchTerm}
                        onKeyPress={this.checkSearchKey}
                        onKeyUp={this.checkBackspaceKey}
                        value={this.state.searchTerm}
                        autoComplete={"off"}/>
                    <InputGroup.Append>
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="search-tooltip">Search</Tooltip>}>
                            <Button variant="primary" type="button" onClick={this.search}>
                                <SearchIcon />
                            </Button>
                        </OverlayTrigger>
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="reset-tooltip">Clear search</Tooltip>}>
                            <Button variant="dark" type="button" onClick={this.reset}>
                                <ClearIcon />
                            </Button>
                        </OverlayTrigger>
                    </InputGroup.Append>
                </InputGroup>
            </FormEntry>
        );
    }

}