import React, { ReactNode } from "react";
import { Col, Image, OverlayTrigger, Spinner, Row, Popover, Tooltip } from "react-bootstrap";
import styled from "styled-components";
import * as Icons from "react-bootstrap-icons";
import { RouteComponentProps } from "react-router-dom";

import * as utils from "../utils/Utils";
import * as constants from "../constants";
import { createRequest, getMetadataURL, getPromotions } from "../utils/Requests";
import Logger from "../utils/Logger";
import ResultsCarousel from "./ResultCarousel";
import MetadataModal from "./MetadataModal";
import MetadataPopover from "./MetadataPopover";
import moment from "moment";
import Branding from "../config/Branding";
import InfoIcon from "./InfoIcon";

const ResultsContainer = styled.div`
    cursor: pointer;
    display: table-cell;
    vertical-align: middle;
    border-radius: 3px;
`;

const ResultsTitle = styled.div`
    text-align: center;

    @media (min-width: 768px) {
        max-width: 300px;
    }
`;

const ResultsText = styled.div`
    overflow-wrap: break-word;
    text-align: center;
    padding-bottom: 10px;
    line-height: normal;

    @media (min-width: 768px) {
        max-width: 300px;
    }
`;

const MessageContainer = styled.div`
    text-align: center;
    display: flex;
    justify-content: center;
`;

const CarouselMessageContainer = styled(MessageContainer)`
    align-items: center;
    height: 100%;
`;

const LiveIndicator = styled.div`
    position: absolute;
    top: 10px;
    left: 20px;
    z-index: 10;
`;

const ScoreIndicator = styled.div`
    position: absolute;
    top: 7px;
    left: 20px;
    z-index: 10;
    opacity: 95%;
    border-radius: 3px;
    padding: 2px;
    color: white;
    background: gray;
    box-shadow: 0px 0px 15px gray;
`;

const StarIcon = styled(Icons.StarFill)`
    position: absolute;
    bottom: 5px;
    right: 5px;
    z-index: 2;
    color: ${Branding.yellow};
    font-size: 18px;
    filter: drop-shadow(2px 4px 6px black);
`;

const RelativeDiv = styled.div`
    position: relative;
`;

type HistoryObject = RouteComponentProps["history"]

type ResultGridProperties = {
    slotId: string,
    userId?: string,
    hostName: string,
    parameters: string,
    seedId?: string,
    requestOnLoad?: boolean,
    carousel?: boolean,
    genre?: string,
    nameMatches?: Array<string>,
    random?: boolean,
    decade?: boolean,
    onItemClick?: (event: string) => void,
    additionalParameters?: Record<string, string>,
    lookupItems?: boolean,
    customer?: CustomerConfig,
    local?: boolean,
    showMetadataLink?: boolean,
    requestType?: string,
    username: string,
    groups: string[],
    seedCallback?: (arg0: Record<string, any>) => Promise<void>,
    fromBrowse?: boolean,
    aToZ?: boolean,
    zToA?: boolean,
    history?: HistoryObject
}

type PromotionData = {
    metadataId: string,
    promotionId: string;
    promotionName: string;
    endDate: string;
};

type ResultGridState = {
    slotId: string,
    userId: string,
    hostName: string,
    isLoaded: boolean,
    error: Error | null,
    items: Array<any>,
    showModal: boolean,
    selectedItem: Record<string, any>,
    mltItems: Array<any>,
    promotedItems: Record<string, PromotionData>;
    promotions: Array<Record<string,any>>
}

export class ResultGrid extends React.Component<ResultGridProperties, ResultGridState> {

    constructor(props: ResultGridProperties) {
        super(props);
        this.state = {
            slotId: props.slotId,
            userId: props.userId || "",
            hostName: props.hostName,
            isLoaded: false,
            error: null,
            items: [],
            showModal: false,
            selectedItem: {},
            mltItems: [],
            promotedItems: {},
            promotions: []
        };
    }

    public componentDidMount(): void {
        if (this.props.requestOnLoad) {
            this.requestItems();
        }
    }

    public async reset(): Promise<void> {
        this.setState({
            isLoaded: false,
            error: null,
            items: []
        });
    }

    private handleError(error: Error) {
        Logger.error(error);
        this.setState({
            isLoaded: true,
            error
        });
    }

    private seedCallback(seedItem: Record<string, any>) {
        if (this.props.seedCallback) {
            this.props.seedCallback(seedItem);
        }
    }

    private async getPromotions(): Promise<void> {
        if (this.props.customer) {
            const promotions = await getPromotions(this.props.customer);

            this.setState({
                promotions
            });
        }
    }

    public async requestItems(): Promise<void> {
        this.setState({
            isLoaded: false
        });
        this.getPromotions().then(() => {this.getItems(); });
    }

    public async getItems(): Promise<void> {
        const {
            requestType = constants.GET_REQUEST,
            parameters = "",
            seedId = "",
            userId = ""
        } = this.props;

        let request: Request;

        if (requestType === constants.POST_REQUEST) {
            request = await createRequest(
                this.getUrl(false),
                requestType,
                utils.getParametersAsJson(parameters, seedId, userId.trim())
            );
        } else {
            request = await createRequest(
                this.getUrl(true),
                requestType
            );
        }

        try {
            const response = await fetch(request);

            if (response.status !== 200) {
                if (response.status === 404) {
                    throw new Error("404: Not Found");
                } else {
                    const contentType = response.headers.get("content-type");
                    if (contentType && contentType.indexOf("application/json") !== -1) {
                        const error = await response.json();
                        this.handleError(error);
                    } else {
                        const error = await response.text();
                        this.handleError(new Error(error));
                    }
                }
            } else {
                let result = await response.json();

                if (this.props.lookupItems && this.props.customer) {
                    let itemsToLookup = result;

                    if (result["items"]) {
                        itemsToLookup = result["items"];
                    }

                    const itemsToQuery = itemsToLookup;
                    itemsToQuery.forEach((element: Record<string, any>) => {
                        element = {
                            "id": element["id"],
                            "typeName": element["typeName"],
                            "brandId": element["brandId"]
                        };
                    });


                    const metadataRequest = await createRequest(
                        getMetadataURL(this.props.customer),
                        "POST",
                        JSON.stringify(itemsToLookup)
                    );

                    const metadataResponse = await fetch(metadataRequest);

                    if (metadataResponse.ok) {
                        result = await metadataResponse.json();
                    } else {
                        return Promise.reject(metadataResponse);
                    }
                }

                let items = result.items;

                if (this.props.genre) {
                    items = items.filter((n: any, _i: number) => {
                        return n.genre[0].name === this.props.genre;
                    });
                }

                if (this.props.nameMatches) {
                    items = items.filter((item: any, _i: number) => {
                        return this.props.nameMatches?.includes(item.name);
                    }).sort((a: any, b: any) => {
                        return a.custom.top250Rank - b.custom.top250Rank;
                    });
                }

                if (this.props.random) {
                    items = utils.shuffle(items).slice(0, 18);
                }

                if (this.props.decade) {
                    const min = 2010;
                    const max = 2020;
                    items = items.filter((item: any, _i: number) => {
                        return item.custom.releaseYear >= min && item.custom.releaseYear < max;
                    });
                }

                if (this.props.aToZ) {
                    items.sort((a: { name: string; }, b: { name: string; }) => a.name.localeCompare(b.name));
                } else if (this.props.zToA) {
                    items.sort((a: { name: string; }, b: { name: string; }) => b.name.localeCompare(a.name));
                }

                items.forEach((item: any) => {
                    this.updatePromotedItems(item);
                });

                this.setState({
                    isLoaded: true,
                    items: items || [],
                    error: null
                }, () => {
                    if (result.modelInfo?.seed) {
                        this.seedCallback(result.modelInfo.seed);
                    }
                });
            }
        } catch (error) {
            if (error instanceof Error) {
                this.handleError(error);
            }
        }
    }

    private updatePromotedItems = (item: any) => {
        const promotionData = utils.isPromotedItem(item, this.props.slotId, this.state.promotions);

        if (promotionData) {
            this.setState((prevState) => ({
                promotedItems: {
                    ...prevState.promotedItems,
                    [item.id]: promotionData
                }
            }));
        }
    };

    private navigateToPromotions = (event: any) => {
        event.preventDefault();

        if (this.props.history) {
            this.props.history.push(`/promotions?customer=${this.props.customer?.name}`);
        }
    };

    private getResult = (item: any): ReactNode => {
        const promotionData = this.state.promotedItems[item.id];

        const promotionPopover = (
            <Popover id="promotion-popover">
                <Popover.Title as="h3">Promotion Details</Popover.Title>
                <Popover.Content>
                    <strong>Promotion ID:</strong> {promotionData?.promotionId}<br />
                    <strong>Promotion Name:</strong> {promotionData?.promotionName}<br />
                    <strong>End Date:</strong> {moment(promotionData?.endDate).utc().format(constants.DATE_FORMAT)}
                </Popover.Content>
            </Popover>
        );

        return (
            <div>
                {this.props.customer && this.props.showMetadataLink &&
                    <InfoIcon
                        customer={this.props.customer}
                        itemId={item["id"]}
                        openInNewTab
                        resultsGrid
                    />
                }
                <LiveIndicator>{utils.isLiveItem(item)}</LiveIndicator>
                {item["score"] &&
                    <OverlayTrigger
                        placement={"auto"}
                        overlay={<Tooltip id="score-tooltip">Recommendation Score</Tooltip>}>
                        <ScoreIndicator>{(item["score"] * 100).toFixed(2)}</ScoreIndicator>
                    </OverlayTrigger>
                }
                <RelativeDiv>
                    {promotionData &&
                        <OverlayTrigger
                            placement="top"
                            overlay={promotionPopover}
                        >
                            <StarIcon onClick={this.navigateToPromotions} />
                        </OverlayTrigger>
                    }
                    <OverlayTrigger
                        key={item["id"]}
                        placement={"auto"}
                        overlay={<MetadataPopover item={item} />}
                    >
                        <Image
                            src={utils.getImageUrl(item)}
                            width={300}
                            className="img-fluid"
                            alt={item["id"]}
                            onError={(event) => { utils.getPlaceholderImage(event, item); }}
                            onClick={() => { this.displayModal(true, item); }}
                            rounded
                        />
                    </OverlayTrigger>
                </RelativeDiv>
            </div>
        );
    };

    private displayModal(show: boolean, item: Record<string, any> = {}): void {
        this.setState({
            showModal: show,
            selectedItem: item
        });
    }

    public render(): ReactNode {
        const { error, isLoaded } = this.state;

        if (this.props.carousel && this.props.onItemClick) {
            if (error) {
                return <CarouselMessageContainer>
                    Error - {error.message}
                    <div>
                        {error.stack}
                    </div>
                </CarouselMessageContainer>;
            } else if (!isLoaded) {
                return <CarouselMessageContainer><Spinner animation="border" variant="primary" /></CarouselMessageContainer>;
            }

            return <ResultsCarousel customerConfig={this.props.customer} items={this.state.items} onItemClick={this.props.onItemClick} />;
        } else {
            if (error) {
                Logger.error(error.stack);
                return <MessageContainer>
                    Error - {error.message}
                </MessageContainer>;
            } else if (!isLoaded && this.state.items.length === 0) {
                return <MessageContainer>Results appear here</MessageContainer>;
            } else if (!isLoaded) {
                return <MessageContainer><Spinner animation="border" variant="primary" /></MessageContainer>;
            }
            return (
                <>
                    <MetadataModal
                        item={this.state.selectedItem}
                        customer={this.props.customer}
                        showModal={this.state.showModal}
                        closeCallback={() => { this.displayModal(false); }}
                        username={this.props.username}
                        groups={this.props.groups}
                    />
                    <Row className="justify-content-center">
                        {this.state.items && this.state.items.length > 0 &&
                            (this.state.items
                                .map((item: any, i: number) => {
                                    const genres: Array<string> = utils.getGenres(item);

                                    return (
                                        <Col key={i} md={2}>
                                            <ResultsContainer>
                                                {this.getResult(item)}
                                            </ResultsContainer>
                                            <ResultsTitle>
                                                <b>{item["name"]}</b>
                                            </ResultsTitle>
                                            <ResultsText>
                                                {item["typeName"]}
                                                <br />
                                                {genres.length > 0 &&
                                                    <div>{genres.join(" - ")}</div>}
                                            </ResultsText>
                                        </Col>
                                    );
                                })
                            )
                        }
                        {
                            this.state.items.length === 0 && (
                                <MessageContainer>
                                    <h1>No Results!</h1>
                                </MessageContainer>
                            )
                        }
                    </Row>
                </>
            );
        }
    }

    private buildBaseUrl = () => {
        const baseUrl = `${this.props.hostName}/${this.props.local ? this.props.slotId : `slots/${this.props.slotId}/items`}`;
        return utils.addPortalUserInfoToUrl(baseUrl, this.props.username, this.props.groups);
    };

    private buildAdditionalParameters = () => {
        let formattedParameters;

        if (this.props.fromBrowse) {
            formattedParameters = this.props.parameters;
        } else {
            formattedParameters = utils.getFormattedParameters(this.props.parameters);
        }

        const additionalParameters = this.getAdditionalParameters();

        let parameters = "";
        if (formattedParameters) {
            parameters += formattedParameters;
            if (additionalParameters) {
                parameters += `&${additionalParameters}`;
            }
        } else if (additionalParameters) {
            parameters += additionalParameters;
        }

        return parameters;
    };

    private getUrl = (includeParams = true) => {
        let url = this.buildBaseUrl();
        if (includeParams) {
            const parameters = this.buildAdditionalParameters();
            if (parameters) {
                url += `&_debug_metadata=true&ignore=testtool${parameters}`;
            } else {
                url += "&_debug_metadata=true&ignore=testtool";
            }
            if (this.props.userId) {
                url += `&userId=${encodeURIComponent(this.props.userId.trim())}`;
            }
            if (this.props.seedId) {
                url += `&seedIds=${encodeURIComponent(this.props.seedId)}`;
            }
        }

        return url;
    };

    private getAdditionalParameters = () => {
        if (this.props.additionalParameters) {
            return Object.entries(this.props.additionalParameters)
                .map(([key, value]) => {
                    if (value) {
                        return `${key}=${encodeURIComponent(value)}`;
                    } else {
                        return "";
                    }
                })
                .join("&");
        }
        return "";
    };
}