import React, { ReactNode } from "react";
import { Card, OverlayTrigger } from "react-bootstrap";
import styled from "styled-components";

import * as utils from "../utils/Utils";
import MetadataPopover from "./MetadataPopover";
import InfoIcon from "./InfoIcon";

const SeedCard = styled(Card)`
    padding: 10px 0;
    min-height: 50px;
`;

const SeedTitle= styled(Card.Title)`
    text-align: center;
    margin: 10px 0;
`;

const SeedBody = styled(Card.Body)`
    padding-top: 0;
    padding-bottom: 0;
`;

const SeedImage = styled(Card.Img)`
    max-height: 175px;
    width: unset;
`;

const SeedImageSmall = styled(Card.Img)`
    max-height: 110px;
    width: unset;
`;

type SeedInfoProperties = {
    customerConfig: CustomerConfig,
    seedItem?: Record<string, any>,
    hideBody?: boolean
}


export default class SeedInfo extends React.Component<SeedInfoProperties> {

    public render(): ReactNode {
        const { seedItem, hideBody } = this.props;
        if (seedItem) {
            if (hideBody) {
                return (
                    <SeedCard>
                        <OverlayTrigger
                            key={seedItem["id"]}
                            placement={"auto"}
                            overlay={<MetadataPopover item={seedItem} />}
                        >
                            <>
                                {this.props.customerConfig &&
                                    <InfoIcon
                                        customer={this.props.customerConfig}
                                        itemId={seedItem["id"]}
                                        openInNewTab
                                    />
                                }
                                <SeedImageSmall
                                    src={utils.getImageUrl(seedItem)}
                                    className="mx-auto"
                                    alt={seedItem["id"]}
                                    variant="top"
                                    onError={(event: React.SyntheticEvent<HTMLImageElement, Event>) => { utils.getPlaceholderImage(event, seedItem); }}
                                />
                            </>
                        </OverlayTrigger>
                        <SeedTitle><small>{seedItem["name"]}</small></SeedTitle>
                    </SeedCard>
                );
            }
            else {
                return(
                    <SeedCard>
                        {this.props.customerConfig &&
                            <InfoIcon
                                customer={this.props.customerConfig}
                                itemId={seedItem["id"]}
                                openInNewTab
                            />
                        }
                        <SeedImage
                            src={utils.getImageUrl(seedItem)}
                            className="mx-auto"
                            alt={seedItem["id"]}
                            variant="top"
                            onError={(event: React.SyntheticEvent<HTMLImageElement, Event>) => { utils.getPlaceholderImage(event, seedItem); }}
                        />
                        <SeedTitle><small>{seedItem["name"]}</small></SeedTitle>
                        <SeedBody>
                            <b>ID</b>: {seedItem["id"]}<br/>
                            <b>Content Rating</b>: {utils.getContentRating(seedItem)}<br/>
                            <b>Release Year</b>: {utils.getDatePublished(seedItem)}<br/>
                            <b>Genre</b>: {utils.getGenres(seedItem).join(", ")}<br/>
                            <b>Directed By</b>: {utils.getDirector(seedItem["director"]).join(", ")}<br/>
                            <b>Actors</b>: {utils.getActors(seedItem["actor"]).join(", ")}<br/>
                        </SeedBody>
                    </SeedCard>
                );
            }
        } else {
            return (
            <SeedCard>
                <SeedBody className="mx-auto">No Seed Selected</SeedBody>
            </SeedCard>
            );
        }
    }
}
