import React, { ReactNode } from "react";
import { Button, Col, Container, Form, Image, InputGroup, Row, Spinner } from "react-bootstrap";
import { Tv as SeedIcon } from "react-bootstrap-icons";
import styled from "styled-components";

import Branding from "../config/Branding";
import { getMultiItemMetadata } from "../utils/Requests";
import * as utils from "../utils/Utils";
import Logger from "../utils/Logger";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { CentralCol } from "../components/SharedStyled";
import { SearchResults } from "../components/SearchResults";

const ResultsCol = styled(Col)`
    top: 10px;
    text-align:center;
`;

const ScaledImage = styled(Image)`
    height: 60px;
    width: unset;
    max-width: 240px;
    margin-top: 8px;
    margin-bottom: 8px;
`;

const SeedViewerMainRow = styled(Row)`
    background: ${Branding.transparentBackground};
    top: ${props => props.standalone ? "30px" : "63px"};
`;

const SeedViewerFormRow = styled(Row)`
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: -5px;
`;

const SeedViewerElementRow = styled(Row)`
    margin-bottom: 5px;
`;

const SeedViewerFormCol = styled(Col)`
    padding-left: 0 !important;
`;

const SearchResultsRow = styled(Row)`
    overflow-y: auto;
    max-height: 90vh;
`;

type SeedViewerState = {
    seeds: string,
    error: Error | null,
    isLoaded: boolean
    isLoading: boolean,
    items: Record<string, any>[]
}

type SeedViewerProps = {
    standalone?: boolean,
    metadataAccess?: boolean,
    groups: string[],
    username: string
    customerConfig: CustomerConfig,
}

interface SeedViewerProperties extends SeedViewerProps, RouteComponentProps { }

class SeedViewer extends React.Component<SeedViewerProperties, SeedViewerState> {

    constructor(props: SeedViewerProperties) {
        super(props);

        this.state = {
            seeds: "",
            items: [],
            isLoading: false,
            error: null,
            isLoaded: false
        };
    }

    private update = (): void => {
        this.setState({
            isLoading: true,
            isLoaded: false
        }, () => {
            if (this.state.seeds) this.requestItems();
        });
    }

    private handleError(error: Error) {
        Logger.error(error);
        this.setState({
            isLoading: false,
            isLoaded: true,
            error
        });
    }

    public async requestItems(): Promise<void> {
        let seedInput = this.state.seeds;

        seedInput = seedInput.replaceAll("'", "");
        seedInput = seedInput.replaceAll("\"", "");
        seedInput = seedInput.replaceAll("(", "").replaceAll(")", "");
        seedInput = seedInput.replaceAll("[", "").replaceAll("]", "");
        seedInput = seedInput.replaceAll("\n", ",");

        const seedList = seedInput.split(",");

        await getMultiItemMetadata(this.props.customerConfig, seedList).then((response)=> {
            return response.json();
        }).then(jsonOutput => {
            this.setState({
                items: jsonOutput.items,
                isLoading: false,
                isLoaded: true
            });
        }).catch(error => {
            this.handleError(error);
        });
    }

    private setSeeds = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            seeds: event.currentTarget.value
        });
    }

    private renderResults(): ReactNode {
        const {error, isLoading, isLoaded, items} = this.state;

        if (error) {
            return <Row className="justify-content-center">
                Error - {error.message}
            </Row>;
        }

        if (isLoading) {
            return <Spinner animation="border" variant="primary" />;
        }

        if (!isLoading && !isLoaded) {
            return <Row className="justify-content-center">
                Results will appear here
            </Row>;
        } else {
            return <SearchResultsRow>
                <Col style={{overflowY: "auto"}}>
                    <SearchResults
                        customer={this.props.customerConfig}
                        items={items}
                        username={this.props.username}
                        groups={this.props.groups}
                        includeSeed
                        showMetadataLink
                    />
                </Col>
            </SearchResultsRow>;
        }
    }

    public render(): ReactNode {
        return (
            <Container className="mw-100" style={{margin: "5px"}}>
                <SeedViewerMainRow className="justify-content-center" standalone={this.props.standalone ? 1 : 0}>
                    <Col md={3}>
                            <SeedViewerFormRow className="justify-content-center">
                                <CentralCol>
                                    <Row className="justify-content-center">
                                        {this.props.customerConfig.logoLocation &&
                                        <ScaledImage
                                            src={this.props.customerConfig.logoLocation}
                                            onError={(event) => { utils.getErrorLogo(event); }}
                                        />}
                                    </Row>
                                    <Row className="justify-content-center">
                                        <b>Seed Viewer</b>
                                    </Row>
                                    <Row className="justify-content-center">
                                        <p>Supports formatting of seed IDs that include any of the following characters: ,"'[]() \n</p>
                                    </Row>
                                    <SeedViewerElementRow>
                                        <InputGroup>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text><SeedIcon size="22" /></InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control
                                                id="parametersInput"
                                                as="textarea"
                                                rows={20}
                                                placeholder="Enter seeds here..."
                                                onChange={this.setSeeds}
                                                value={this.state.seeds}
                                                style={{ "padding": "0.375rem 0.375rem" }} />
                                        </InputGroup>
                                    </SeedViewerElementRow>
                                    <SeedViewerElementRow>
                                        <SeedViewerFormCol style={{ textAlign: "right", paddingRight: "0px" }}>
                                            <Button variant="primary" size="sm" type="button" onClick={this.update}>
                                                Submit
                                            </Button>
                                        </SeedViewerFormCol>
                                    </SeedViewerElementRow>
                                </CentralCol>
                            </SeedViewerFormRow>
                    </Col>
                    <ResultsCol md={9} className="justify-content-center">
                        {this.state.items && this.renderResults()}
                    </ResultsCol>
                </SeedViewerMainRow>
            </Container>
        );
    }
}

export default withRouter(SeedViewer);