import React, { ReactNode } from "react";
import { Button, Col, Form, InputGroup, OverlayTrigger, Row, Modal, Spinner, Tooltip } from "react-bootstrap";
import styled from "styled-components";
import { Trash as ClearIcon, Search as SearchIcon } from "react-bootstrap-icons";

import { SearchResults } from "./SearchResults";
import * as utils from "../utils/Utils";
import { getSearchURL, makeSlotRequest } from "../utils/Requests";
import Logger from "../utils/Logger";
import { CentralCol } from "../components/SharedStyled";

const SearchFormRow = styled(Form.Row)`
    margin-top: 10px;
    margin-bottom: 20px;
`;

const SearchElementRow = styled(Form.Row)`
    margin-bottom: 5px;
`;

const SearchResultsRow = styled(Row)`
    max-height: 67vh;
    overflow-y: auto;
`;

const SearchModalBody = styled(Modal.Body)`
    min-height: 80vh;
    height: 80vh;
    max-height: 80vh;
`;

type SearchModalProperties = {
    customer: CustomerConfig,
    showModal: boolean,
    userId: string,
    closeCallback: () => void,
    searchCallback?: (item: Record<string, any>) => void,
    username: string,
    groups: string[],
    clickToSelect?: boolean
}

type SearchModalState = {
    searchTerm: string,
    searchParameters: string,
    items: Array<Record<string, any>>,
    isLoading: boolean,
    isLoaded: boolean,
    error: Error | null
}

export default class SearchModal extends React.Component<SearchModalProperties, SearchModalState> {

    private timeoutId: NodeJS.Timeout | null;

    constructor(props: SearchModalProperties) {
        super(props);

        this.timeoutId = null;

        this.state = {
            items: [],
            isLoading: false,
            isLoaded: false,
            error: null,
            searchTerm: "",
            searchParameters: ""
        };
    }

    private checkKey = (event: React.KeyboardEvent): void => {
        if (event.key === "Enter" || event.key === "NumpadEnter") {
            if (this.timeoutId) {
                clearTimeout(this.timeoutId);
            }
            this.update();
        }
        else if (this.state.searchTerm.length >= 1) {
            if (this.timeoutId) {
                clearTimeout(this.timeoutId);
            }
            this.timeoutId = setTimeout(this.update, 500);
        }
        else if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    }

    private checkBackspaceKey = (event: React.KeyboardEvent): void => {
        if ((event.key === "Backspace" || event.key === "Delete") && this.state.searchTerm.length >= 2) {
            if (this.timeoutId) {
                clearTimeout(this.timeoutId);
            }
            this.timeoutId = setTimeout(this.update, 500);
        }
    }

    private getUrl = (includeParams = true): string => {
        let url = getSearchURL(this.props.customer);

        if (includeParams) {
            const formattedParameters = utils.getFormattedParameters(this.state.searchParameters);
            url += `?_debug_metadata=true&ignore=testtool&userId=${encodeURIComponent(this.props.userId.trim())}`;
            url += formattedParameters;

        }

        url = utils.addPortalUserInfoToUrl(url, this.props.username, this.props.groups);

        return url;
    }

    public async requestItems(): Promise<void>  {
        await makeSlotRequest(this.getUrl(true))
            .then((response) => {
                if (response.status !== 200) {
                    if (response.status === 404) {
                        throw new Error("404: Not Found");
                    } else {
                        const contentType = response.headers.get("content-type");
                        if (contentType && contentType.indexOf("application/json") !== -1) {
                            response.json().then((error) => {
                                this.handleError(error);
                            });
                        } else {
                            response.text().then((error) => {
                                this.handleError(new Error(error));
                            });
                        }
                    }
                } else {
                    response.json().then(async (result) => {
                        if (result) {
                            const items = result.items;

                            this.setState({
                                isLoading: false,
                                isLoaded: true,
                                items,
                                error: null
                            });
                        }
                    });
                }
            }).catch((error) => {
                this.handleError(error);
            });
    }

    private update = (): void => {
        this.setState({
            isLoading: true,
            isLoaded: false
        }, this.requestItems);
    }

    private handleError(error: Error) {
        Logger.error(error);
        this.setState({
            isLoading: false,
            isLoaded: true,
            error
        });
    }

    private reset = (): void => {
        this.setState({
            searchTerm: "",
            searchParameters: "",
            items: [],
            isLoading: false,
            isLoaded: false,
            error: null
        });
    }

    private setSearchTerm = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            searchTerm: event.currentTarget.value,
            searchParameters: "q:" + event.currentTarget.value
        });
    }

    private searchCallback = (item: Record<string,any>) => {
        if (this.props.searchCallback) {
            this.props.searchCallback(item);
        }

        if (this.props.clickToSelect) {
            this.props.closeCallback();
        }
    }

    private renderSearchResults(): ReactNode {
        const {error, isLoading, isLoaded, items} = this.state;

        if (error) {
            return <Row className="justify-content-center">
                Error - {error.message}
            </Row>;
        }

        if (isLoading) {
            return <Spinner animation="border" variant="primary" />;
        }

        if (!isLoading && !isLoaded) {
            return <Row className="justify-content-center">
                Results will appear here
            </Row>;
        } else {
            return <SearchResultsRow>
                <Col>
                    <SearchResults
                        customer={this.props.customer}
                        items={items}
                        username={this.props.username}
                        groups={this.props.groups}
                        selectCallback={this.props.searchCallback && this.searchCallback}
                        clickToSelect={this.props.clickToSelect}
                    />
                </Col>
            </SearchResultsRow>;
        }
    }

    public render(): ReactNode {
        return (
            <Modal
                show={this.props.showModal}
                centered
                backdrop="static"
                dialogClassName="modal-80w"
                onHide={() => {
                    this.reset();
                    this.props.closeCallback();
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Search</Modal.Title>
                </Modal.Header>
                <SearchModalBody>
                    <Row>
                    <CentralCol md>
                        <Form className="SearchSettings" autoComplete="off" onSubmit={(event) => { event.preventDefault(); }}>
                            <SearchFormRow className="justify-content-center">
                            <Col md={5}>
                                    <SearchElementRow>
                                        <InputGroup>
                                            <Form.Control
                                                id="searchQueryInput"
                                                type="string"
                                                value={this.state.searchTerm}
                                                onChange={this.setSearchTerm}
                                                onKeyPress={this.checkKey}
                                                onKeyUp={this.checkBackspaceKey}
                                                placeholder="Search Terms, e.g. Title, Actor"/>
                                            <InputGroup.Append>
                                                <OverlayTrigger
                                                    placement="right"
                                                    overlay={<Tooltip id="search-tooltip">Search</Tooltip>}>
                                                    <Button variant="primary" type="button" onClick={this.update}>
                                                        <SearchIcon />
                                                    </Button>
                                                </OverlayTrigger>

                                                <OverlayTrigger
                                                    placement="right"
                                                    overlay={<Tooltip id="reset=tooltip">Reset search</Tooltip>}>
                                                    <Button variant="dark" type="button" onClick={this.reset}>
                                                        <ClearIcon />
                                                    </Button>
                                                </OverlayTrigger>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </SearchElementRow>
                                    {this.props.clickToSelect && <Row className="justify-content-center">
                                        <Col>
                                            Click an item to select it as the seed
                                        </Col>
                                    </Row>}
                                </Col>
                            </SearchFormRow>
                        </Form>
                    </CentralCol>
                    </Row>
                    <Row className="justify-content-center">
                        <CentralCol md>
                            {this.renderSearchResults()}
                        </CentralCol>
                    </Row>
                </SearchModalBody>
            </Modal>
        );
    }
}
