import React, { ReactNode, Component, createRef, RefObject } from "react";
import { Alert, Col, Container, Row, Tabs, Tab } from "react-bootstrap";
import styled from "styled-components";
import Branding from "../config/Branding";
import ListTab from "../components/lanebuilder/ListTab";
import SelectionTab from "../components/lanebuilder/SelectionTab";
import TestTab from "../components/lanebuilder/TestTab";
import { AlertTopRight, CentralCol } from "../components/SharedStyled";

const CustomerRow = styled(Row)`
    background: ${Branding.transparentBackground};
    top: ${(props) => (props.standalone ? "30px" : "63px")};
    margin-top: 10px;
`;

const GroupLabel = styled(Alert)`
    height: 35px;
    line-height: 0.8;
`;

type LaneBuilderState = {
    selectedItems: Array<SearchItem>,
    alertMsg: string,
    alertType: "success" | "warning",
    selectedEditorialID: string,
    selectedEditorialName: string,
    tabKey: string
}

type LaneBuilderProperties = {
    groups: string[],
    standalone?: boolean,
    username: string
    customerConfig: CustomerConfig
}

const LIST_KEY = "list";
const BUILD_KEY = "build";
const TEST_KEY = "test";

export default class LaneBuilder extends Component<LaneBuilderProperties, LaneBuilderState> {
    private selectionTabElement: RefObject<SelectionTab> = createRef();
    private listTabElement: RefObject<ListTab> = createRef();

    state: LaneBuilderState = {
        selectedItems: [],
        alertMsg: "",
        alertType: "success",
        selectedEditorialID: "",
        selectedEditorialName: "",
        tabKey: LIST_KEY
    };

    private setEditorial = (editorialID: string, editorialName: string): void => {
        this.setState({
            selectedEditorialID: editorialID,
            selectedEditorialName: editorialName,
            tabKey: BUILD_KEY
        });
    }

    private setSelectedItems = (selectedItems: Array<SearchItem>): void => {
        this.setState({
            selectedItems
        });
    }

    private displayAlert = (alertType: "warning" | "success", alertMsg: string): void => {
        this.setState({
            alertType,
            alertMsg
        }, () => { setTimeout(this.closeAlert, 2500); });
    }

    private closeAlert = (): void => {
        this.setState({
            alertMsg: ""
        });
    }

    private setKey = (tabKey: string | null): void => {
        if (tabKey) {
            this.setState({
                tabKey
            });
        }
    }

    public render(): ReactNode {
        const { selectedEditorialName, alertType, alertMsg, tabKey, selectedEditorialID } = this.state;
        const { customerConfig, username, groups } = this.props;

        return (
            <div>
                <Container className="mw-100" key="editorial-banner-tool">
                    <CustomerRow className="mx-auto">
                        <Col>
                            <Row>
                                {selectedEditorialName &&
                                    <GroupLabel variant="secondary">
                                        Selected Lane: {selectedEditorialName}
                                    </GroupLabel>
                                }
                            </Row>
                        </Col>
                        <Col>
                            <AlertTopRight
                                variant={alertType}
                                show={alertMsg.length > 0}
                                onClose={this.closeAlert}
                                dismissible
                            >
                                {alertMsg}
                            </AlertTopRight>
                        </Col>
                    </CustomerRow>
                    <Row className="justify-content-center">
                        <CentralCol md>
                            <Tabs activeKey={tabKey} defaultActiveKey={LIST_KEY} onSelect={this.setKey}>
                                <Tab key={LIST_KEY} eventKey={LIST_KEY} title="List" unmountOnExit>
                                    <ListTab
                                        ref={this.listTabElement}
                                        customerConfig={customerConfig}
                                        portalUsername={username}
                                        portalGroups={groups}
                                        selectCallback={this.setEditorial}
                                    />
                                </Tab>
                                <Tab key={BUILD_KEY} eventKey={BUILD_KEY} title="Build" unmountOnExit>
                                    <SelectionTab
                                        ref={this.selectionTabElement}
                                        customer={customerConfig.name}
                                        alertCallback={this.displayAlert}
                                        selectionCallback={this.setSelectedItems}
                                        selectedEditorialID={selectedEditorialID}
                                        selectedEditorialName={selectedEditorialName}
                                    />
                                </Tab>
                                <Tab key={TEST_KEY} eventKey={TEST_KEY} title="Test" unmountOnExit>
                                    <TestTab
                                        customer={customerConfig.name}
                                        portalUsername={username}
                                        portalGroups={groups}
                                        selectedEditorialID={selectedEditorialID}
                                    />
                                </Tab>
                            </Tabs>
                        </CentralCol>
                    </Row>
                </Container>
            </div>
        );
    }
}

