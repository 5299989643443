import React, { ReactNode } from "react";
import { Button, InputGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import { USER, SEED, GET_REQUEST } from "../../constants";
import { Person as UserIcon, Tv as SeedIcon, Shuffle as ShuffleIcon, Search as SearchIcon, Trash as ClearIcon } from "react-bootstrap-icons";
import { createRequest, getInfoRandomURL, getItemMetadata } from "../../utils/Requests";
import SearchModal from "../SearchModal";
import { FieldValue } from "../SharedStyled";

type RandomFieldProperties = {
    customer: CustomerConfig | undefined,
    type: "user" | "seed",
    initialValue?: string,
    includeIcon?: boolean,
    small?: boolean,
    autocomplete?: boolean,
    padding?: boolean,
    placement? : "auto" | "left" | "right" | "top" | "bottom" | "auto-start" | "auto-end" | "top-start" | "top-end" | "bottom-start" | "bottom-end" | "right-start" | "right-end" | "left-start" | "left-end" | undefined,
    onChangeCallback?: (callbackValue: string, isSubmittable: boolean) => void,
    onKeyUpCallback?: (keyCallbackEvent: React.KeyboardEvent) => void,
    initialRandom?: boolean
    showName?: boolean,
    clearable?: boolean
}

type RandomFieldState = {
    value: string,
    seedName: string,
    showSearchModal: boolean,
}

export default class RandomField extends React.Component<RandomFieldProperties, RandomFieldState> {

    private randomIds: Array<string> = [];

    constructor(props: RandomFieldProperties) {
        super(props);

        if (this.props.customer && this.props.type) {
            this.getRandomIds();
        }

        this.state = {
            value: this.props.initialValue || "",
            seedName: "",
            showSearchModal: false
        };
    }

    private async getRandomIds(): Promise<void> {
        const randomUrl = getInfoRandomURL(this.props.customer!).replace("{topic}", this.props.type);
        const randomRequest = await createRequest(randomUrl, GET_REQUEST);
        const type = this.props.type;
        this.randomIds = await fetch(randomRequest).then(function(response){
            return response.json();
        }).then(function(responseJSON) {
            return responseJSON["info"][type];
        });
    }

    private async getSeedName(): Promise<void> {
        await getItemMetadata(this.props.customer!, this.state.value).then(async (response) => {
            const responseJSON = await response.json();
            if (responseJSON.items.length > 0) {
                const seedName = responseJSON.items[0]["name"];

                this.setState({
                    seedName
                });
            }
        });
    }

    public componentDidMount(): void {
        if (this.props.type === SEED) {
            this.getSeedName();
        }

        if (this.props.onChangeCallback) {
            this.props.onChangeCallback(this.state.value, false);
        }
    }

    public componentDidUpdate(prevProps: RandomFieldProperties): void {
        if (this.props.initialValue !== prevProps.initialValue) {
            let valueToSet = this.props.initialValue;
            if (valueToSet === undefined) {
                valueToSet = "";
            }
            this.setState({
                value: valueToSet
            });
        }
    }

    private handleOnChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        this.setState({
            value: event.target.value
        });
        if (this.props.onChangeCallback) {
            this.props.onChangeCallback(event.target.value, false);
        }
    }

    private handleKeyUp = (event: React.KeyboardEvent): void => {
        if (this.props.onKeyUpCallback) {
            this.props.onKeyUpCallback(event);
        }
    }

    private getRandomID(): void {
        if (this.props.customer) {
            const randomId = Math.floor(Math.random() * this.randomIds.length);

            this.setState({
                value: this.randomIds[randomId] || ""
            }, () => {
                if (this.props.type === SEED) {
                    this.getSeedName();
                }

                if (this.props.onChangeCallback) {
                    this.props.onChangeCallback(this.randomIds[randomId] || "", true);
                }
            });
        }
    }

    private clear(): void {
        this.setState({
            value: "",
            seedName: ""
        }, () => {
            if (this.props.onChangeCallback) {
                this.props.onChangeCallback("", true);
            }
        });
    }

    private selectItem = (item: Record<string,any>): void => {
        this.setState({
            value: item.id
        }, () => {
            if (this.props.type === SEED) {
                this.getSeedName();
            }

            if (this.props.onChangeCallback) {
                this.props.onChangeCallback(item.id, true);
            }
        });
    }

    private displaySearchModal(show: boolean): void {
        this.setState({
            showSearchModal: show
        });
    }

    public render(): ReactNode {
        return (
            <>
            <SearchModal
                customer={this.props.customer!}
                showModal={this.state.showSearchModal}
                userId=""
                closeCallback={() => { this.displaySearchModal(false); }}
                username=""
                groups={[]}
                searchCallback={(item: Record<string, any>) => {this.selectItem(item); }}
                clickToSelect={true}
            />
            <InputGroup>
                {this.props.type === USER && this.props.includeIcon &&
                <InputGroup.Prepend>
                    <InputGroup.Text>
                        {this.props.small && <UserIcon />}
                        {!this.props.small && <UserIcon size="22" />}
                    </InputGroup.Text>
                </InputGroup.Prepend>}
                {this.props.type === SEED && this.props.includeIcon &&
                <InputGroup.Prepend>
                    <InputGroup.Text>
                        {this.props.small && <SeedIcon />}
                        {!this.props.small && <SeedIcon size="22" />}
                    </InputGroup.Text>
                </InputGroup.Prepend>}
                <FieldValue
                    id={this.props.type === USER ? "randomUserIdInput" : "randomSeedIdInput"}
                    type="string"
                    placeholder={this.props.type === USER ? "User Id" : "Seed Id"}
                    onChange={this.handleOnChange}
                    onKeyUp={this.handleKeyUp}
                    value={this.state.value}
                    size={this.props.small ? "sm" : ""}
                />
                <InputGroup.Append>
                    <OverlayTrigger
                        placement={this.props.placement ? this.props.placement : "right"}
                        overlay={<Tooltip id="random-tooltip">Select a random {this.props.type === USER ? "User" : "Seed"} Id</Tooltip>}>
                        <Button variant="primary" type="button" size={this.props.small ? "sm": undefined} onClick={this.getRandomID.bind(this)}>
                            <ShuffleIcon />
                        </Button>
                    </OverlayTrigger>
                </InputGroup.Append>
                {this.props.type === SEED && <InputGroup.Append>
                    <OverlayTrigger
                        placement={this.props.placement ? this.props.placement : "right"}
                        overlay={<Tooltip id="search-tooltip">Search for a seed</Tooltip>}>
                        <Button variant="secondary" type="button" size={this.props.small ? "sm": undefined} onClick={() => { this.displaySearchModal(true); }}>
                            <SearchIcon />
                        </Button>
                    </OverlayTrigger>
                </InputGroup.Append>}
                {this.props.clearable && <InputGroup.Append>
                    <OverlayTrigger
                        placement={this.props.placement ? this.props.placement : "right"}
                        overlay={<Tooltip id="clear-tooltip">Clear</Tooltip>}>
                        <Button variant="danger" type="button" size={this.props.small ? "sm": undefined} onClick={() => { this.clear(); }}>
                            <ClearIcon />
                        </Button>
                    </OverlayTrigger>
                </InputGroup.Append>}
            </InputGroup>
            {this.props.type === SEED && this.props.showName && <>{this.state.seedName}</>}
            </>
        );
    }
}
