import React, { ReactNode } from "react";
import styled from "styled-components";
import { InfoCircleFill } from "react-bootstrap-icons";

const InfoLink = styled.a`
    position: absolute;
    color: white;
    z-index: 10;
`;

const InfoCircle = styled(InfoCircleFill)`
    color: white;
    background: black;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0 0 10px black;
`;



type InfoIconProperties = {
    customer: CustomerConfig,
    itemId: string,
    openInNewTab?: boolean,
    resultsGrid?: boolean,
    search?: boolean
}

export default class InfoIcon extends React.Component<InfoIconProperties> {

    public render(): ReactNode {
        let linkStyle = {top: "2px", right: "10px"};
        let circleStyle = {height: "15px", width: "15px"};

        if (this.props.resultsGrid) {
            linkStyle = {top: "5px", right: "20px"};
            circleStyle = {height: "18px", width: "18px"};
        } else if (this.props.search) {
            linkStyle = {top: "3px", right: "20px"};
            circleStyle = {height: "18px", width: "18px"};
        }

        return (
            <InfoLink
                style={linkStyle}
                onClick={(event) => { event.stopPropagation(); }}
                href={`metadata?seedId=${this.props.itemId}&customer=${this.props.customer.name}`}
                target={this.props.openInNewTab ? "_blank" : "_self"} rel="noopener noreferrer">
                <InfoCircle style={circleStyle} />
            </InfoLink>
        );
    }
}