import React from "react";
import { Alert, Button, Col, Form, InputGroup, Row, Modal } from "react-bootstrap";
import styled from "styled-components";

import Logger from "../../utils/Logger";
import moment from "moment";

import { DATE_FORMAT, ENDDATE_FORMAT, STARTDATE_FORMAT } from "../../constants";
import { deleteEditorial, postBlockingEditorial } from "../../utils/Requests";
import { FieldName, FormRow } from "../SharedStyled";

const ModalHeader = styled(Modal.Header)`
    padding-bottom: 0px;
`;

const DetailsRow = styled(Row)`
    padding-top: 10px;
    padding-bottom: 10px;
`;

const BlockItemAlert = styled(Alert)`
    position: absolute;
    z-index: 10;
    right: 0;
    width: 400px;
    height: 50px;
`;

type EditBlockModalState = {
    description: string,
    startDate: string,
    endDate: string,
    slotId: string,
    validated: boolean,
    showBlockingError: boolean,
}

type EditBlockModalProperties = {
    customerConfig: CustomerConfig,
    showModal: boolean,
    closeCallback: () => void,
    editCallback: () => void,
    blockedItem: BlockedItem,
    slots: Slot[]
}

export default class EditBlockModal extends React.Component<EditBlockModalProperties, EditBlockModalState> {

    constructor(props: EditBlockModalProperties) {
        super(props);

        this.state = {
            slotId: this.props.blockedItem.slotId,
            description: this.props.blockedItem.description,
            startDate: moment(this.props.blockedItem.startDate).format(DATE_FORMAT),
            endDate: moment(this.props.blockedItem.endDate).format(DATE_FORMAT),
            validated: false,
            showBlockingError: false
        };
    }

    // Event Handlers
    private setDescription = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            description: event.currentTarget.value
        });
    }

    private setStartDate = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            startDate: event.currentTarget.value
        });
    }

    private setEndDate = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            endDate: event.currentTarget.value
        });
    }

    private setSlotId = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            slotId: event.currentTarget.value
        });
    }

    private closeModal = (): void => {
        this.props.closeCallback();
    }

    private closeError = (): void => {
        this.setState({
            showBlockingError: false
        });
    }

    // State management methods
    private resetStateAndClose() {
        this.setState({
            validated: false,
            showBlockingError: false
        }, () => {
            this.props.editCallback();
            this.props.closeCallback();
        });
    }

    private handleError(error: unknown) {
        if (error instanceof Error || error instanceof Response) {
            Logger.error(error);
            this.setState({
                showBlockingError: true
            });
        } else {
            Logger.error(new Error("Unexpected error type"));
        }
    }

    // Helper methods
    private getAvailableSlots(): React.ReactNode {
        const allOption = {
            slotId: "all",
            slotName: "All Slots"
        };

        const combinedSlots = [allOption, ...this.props.slots];

        return combinedSlots.sort((a, b) => {
            return a.slotName.localeCompare(b.slotName);
        }).map((slot) => {
            return (
                <option value={slot.slotId} key={slot.slotId}>
                    {slot.slotName} ({slot.slotId})
                </option>
            );
        });
    }

    private renderImageAndName = (isPotrait: boolean) => {
        const { blockedItem } = this.props;
        const imageStyle: React.CSSProperties = isPotrait
            ? { width: "auto", height: "200px", paddingRight: "10px" }
            : { width: "auto", height: "200px" };

        return (
            <>
                <Row className="justify-content-center mb-2">
                    <b><big>{blockedItem["thingName"]}</big></b>
                </Row>
                <Row className="justify-content-center">
                    <img src={blockedItem["thingImageUrl"]} style={imageStyle} alt={blockedItem["thingName"]} />
                </Row>
            </>
        );
    }

    // Main logic methods
    private editBlocking = async () => {
        try {
            const changedBlocking = {
                "slotId": this.state.slotId,
                "thingId": this.props.blockedItem.thingId,
                "boostPercentage": 0,
                "block": true,
                "startDate": moment(this.state.startDate).utc().format(STARTDATE_FORMAT),
                "endDate": moment(this.state.endDate).utc().format(ENDDATE_FORMAT),
                "description": this.state.description
            };

            await deleteEditorial(
                this.props.customerConfig,
                this.props.blockedItem.slotId,
                this.props.blockedItem.thingId
            );

            // Would need to change request function otherwise
            const blockingsArray = [changedBlocking];

            const response = await postBlockingEditorial(
                this.props.customerConfig,
                blockingsArray
            );

            if (response.ok) {
                if (response.status === 200) {
                    this.resetStateAndClose();
                } else {
                    throw new Error(`Error during save, API response: ${response.status} - ${response.statusText}`);
                }
            }
        } catch (error) {
            this.handleError(error);
        }
    };

    public render(): React.ReactNode {
        return (
            <Modal
                show={this.props.showModal}
                centered
                backdrop="static"
                dialogClassName="modal-60w"
                onHide={this.closeModal}
            >
                <ModalHeader closeButton style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingLeft: "7rem"
                }}>
                    <Modal.Title>Edit the blocked item</Modal.Title>
                </ModalHeader>
                <Modal.Body>
                    <BlockItemAlert
                        variant='danger'
                        show={this.state.showBlockingError}
                        onClose={this.closeError}
                        dismissible
                    >
                        Error saving edit, please try again.
                    </BlockItemAlert>
                    <DetailsRow>
                        <Col className="mx-auto" md={6} style={{ marginLeft: "10px", marginRight: "10px" }}>
                            <Form noValidate validated={this.state.validated} onSubmit={
                                (event) => {
                                    event.preventDefault();
                                    this.setState({
                                        validated: true
                                    });

                                    const formValid = event.currentTarget.checkValidity();
                                    if (formValid) {
                                        this.editBlocking();
                                    }
                                }}>
                                <FormRow>
                                    <Col className="mb-3">
                                        <InputGroup>
                                            <InputGroup.Prepend>
                                                <FieldName>Slot</FieldName>
                                            </InputGroup.Prepend>
                                            <Form.Control
                                                as="select"
                                                id="dropdown-slot"
                                                title="Slot"
                                                style={{ "padding": "0.375rem" }}
                                                value={this.state.slotId}
                                                onChange={this.setSlotId}
                                            >
                                                {this.getAvailableSlots()}
                                            </Form.Control>
                                        </InputGroup>
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Col className="mb-3">
                                        <InputGroup hasValidation>
                                            <InputGroup.Prepend>
                                                <FieldName>Description</FieldName>
                                            </InputGroup.Prepend>
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                id="blocking-name"
                                                required
                                                style={{ "padding": "0.375rem" }}
                                                value={this.state.description}
                                                placeholder="Description"
                                                onChange={this.setDescription}
                                            />
                                        </InputGroup>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a description for these blocked items.
                                        </Form.Control.Feedback>
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Col className="mb-3">
                                        <InputGroup>
                                            <InputGroup.Prepend>
                                                <FieldName>From</FieldName>
                                            </InputGroup.Prepend>
                                            <Form.Control
                                                type="date"
                                                id={"Blocking-from"}
                                                style={{ "padding": "0.375rem" }}
                                                value={this.state.startDate}
                                                onChange={this.setStartDate}
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <InputGroup>
                                            <InputGroup.Prepend>
                                                <FieldName>To</FieldName>
                                            </InputGroup.Prepend>
                                            <Form.Control
                                                type="date"
                                                id="Blocking-to"
                                                style={{ "padding": "0.375rem" }}
                                                value={this.state.endDate}
                                                onChange={this.setEndDate}
                                            />
                                        </InputGroup>
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Col className="d-flex justify-content-end">
                                        <Button type="submit">Save Update</Button>
                                    </Col>
                                </FormRow>
                            </Form>
                        </Col>
                        <Col md={4} style={{ borderLeft: "thin gray solid" }}>
                            {this.props.customerConfig.portraitImages ? this.renderImageAndName(true) : this.renderImageAndName(false)}
                        </Col>
                    </DetailsRow>
                </Modal.Body>
            </Modal>
        );
    }
}