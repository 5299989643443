import React, { ReactNode } from "react";
import { Button, Col, Form, InputGroup, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import {
    X as CrossIcon
} from "react-bootstrap-icons";
import styled from "styled-components";
import Branding from "../../config/Branding";
import { FieldIcon } from "../SharedStyled";

const ElementRow = styled(Row)`
    background-color: ${Branding.superDarkBackground};
    border-radius: 3px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px 5px;
`;

const ComposerForm = styled(Form)`
    width: 100%;
`;

const DeleteButton = styled(Button)`
    padding: 6px;
    margin-left: 10px;
`;


type ExperimentRowProperties = {
    id:string,
    position: string,
    models: Array<string>,
    selectedModel: string,
    notes: string,
    percentage: number,
    deleteCallback: (mode: string) => void,
    updateModel: (id: string, model: string) => void,
    updateNotes: (id: string, notes: string) => void,
    updatePercentage: (id: string, percentage: number) => void
    removable: boolean
}

type ExperimentRowState = {
    selectedModel: string,
    notes: string,
    percentage: number
}

export class ExperimentRow extends React.Component<ExperimentRowProperties, ExperimentRowState> {

    constructor(props: ExperimentRowProperties) {
        super(props);

        this.state = {
            selectedModel: props.selectedModel,
            notes: props.notes,
            percentage: props.percentage
        };
    }

    private setModelVerson = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            selectedModel: event.currentTarget.value
        });
        this.props.updateModel(this.props.id,event.currentTarget.value);
    }

    private setNotes = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.updateNotes(this.props.id, event.currentTarget.value);
    }

    private setPercentage = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.updatePercentage(this.props.id, Number(event.currentTarget.value));
    }

    private deleteCallback() {
        this.props.deleteCallback(this.props.id);
    }

    public render(): ReactNode {
        return (
            <ElementRow key={`Experiment-${this.props.position}`}>
                <ComposerForm inline onSubmit={(event: Event) => { event.preventDefault(); }}>
                    <Col md={12}>
                        <Form.Row>
                            <Col md={2}>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <OverlayTrigger
                                            overlay={<Tooltip id="experiment-tooltip">Experiment</Tooltip>}
                                        >
                                            <FieldIcon>Experiment</FieldIcon>
                                        </OverlayTrigger>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        type="text"
                                        value={this.props.position}
                                        disabled={true}
                                    />
                                </InputGroup>
                            </Col>
                            <Col md={3}>
                                <InputGroup>
                                        <InputGroup.Prepend>
                                            <OverlayTrigger
                                                overlay={<Tooltip id="model-version-tooltip">Model Version</Tooltip>}
                                            >
                                                <FieldIcon>Model Version</FieldIcon>
                                            </OverlayTrigger>
                                        </InputGroup.Prepend>
                                    <Form.Control
                                        as="select"
                                        value={this.props.selectedModel}
                                        onChange={this.setModelVerson}
                                    >
                                        {this.props.models.map((model, i) => {
                                            return <option value={model} key={i}>{model}</option>;
                                        })}
                                    </Form.Control>
                                </InputGroup>
                            </Col>
                            <Col md={5}>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <OverlayTrigger
                                            overlay={<Tooltip id="notes-tooltip">Notes</Tooltip>}
                                        >
                                            <FieldIcon>Notes</FieldIcon>
                                        </OverlayTrigger>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        type="string"
                                        placeholder="Experiment Notes"
                                        value={this.props.notes}
                                        onChange={this.setNotes}
                                    >
                                    </Form.Control>
                                </InputGroup>
                            </Col>
                            <Col md={1}>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <OverlayTrigger
                                            overlay={<Tooltip id="percentage-tooltip">Percentage</Tooltip>}
                                        >
                                            <FieldIcon>%</FieldIcon>
                                        </OverlayTrigger>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        type="string"
                                        value={this.props.percentage}
                                        style={{textAlign: "right"}}
                                        onChange={this.setPercentage}
                                    >
                                    </Form.Control>
                                </InputGroup>
                            </Col>
                            <Col md={1} className="d-flex justify-content-end">
                                <DeleteButton disabled={!this.props.removable} variant="danger" onClick={() => {this.deleteCallback();}}>
                                    <CrossIcon size="22" />
                                </DeleteButton>
                            </Col>
                        </Form.Row>
                    </Col>
                </ComposerForm>
            </ElementRow >
        );
    }
}