import React, { ReactNode } from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import styled from "styled-components";
import BlockingModal from "../components/blocking/BlockingModal";

import {
    getSlotsForCustomer,
    getAllBlocks,
    getMultiItemMetadata,
    deleteEditorial
} from "../utils/Requests";
import { getImageUrl, seedIdCheck } from "../utils/Utils";
import RemoveBlockingModal from "../components/blocking/RemoveBlockingModal";
import BlockingCard from "../components/blocking/BlockingCard";
import EditBlockModal from "../components/blocking/EditBlockModal";
import Branding from "../config/Branding";
import { AlertMidRight } from "../components/SharedStyled";

const AddBlockingCol = styled(Col)`
    background: ${Branding.superDarkBackground};
    margin-top: 10px;
    border-radius: 3px;
    padding: 5px;
`;

const StyledRow = styled(Row)`
  padding-left: 15px;
  padding-right: 15px;
`;

type BlockingState = {
    slots: Slot[],
    slotLoading: boolean,
    blockedItems: BlockedItem[],
    blocksLoading: boolean,
    error: any,
    showAddBlockModal: boolean,
    showRemoveBlockModal: boolean,
    showEditBlockModal: boolean,
    showSuccess: boolean,
    showEditSucces: boolean,
    showError: boolean,
    selectedBlockedItem?: BlockedItem
}

type BlockingProperties = {
    standalone?: boolean,
    groups: string[],
    username: string,
    customerConfig: CustomerConfig
}

// A warning is happening here with react state update,
// Tried adding an ismounted flag and tried using abort controller already no result
class Blocking extends React.Component<BlockingProperties, BlockingState> {

    constructor(props: BlockingProperties) {
        super(props);

        this.state = {
            slots: [],
            slotLoading: true,
            blockedItems: [],
            blocksLoading: true,
            error: null,
            showAddBlockModal: false,
            showRemoveBlockModal: false,
            showEditBlockModal: false,
            showSuccess: false,
            showEditSucces: false,
            showError: false
        };
    }

    public componentDidMount(): void {
        this.getSlots();
        this.getBlockedItems();
    }

    private async getSlots(): Promise<void> {
        this.setState({
            slotLoading: true
        });
        await getSlotsForCustomer(this.props.customerConfig).then((slots: Array<Slot>) => {
            this.setState({
                slots,
                slotLoading: false
            });
        }).catch((error) => {
            this.setState({ slotLoading: false, error });
        });
    }

    private async getBlockedItems(): Promise<void> {
        const { customerConfig } = this.props;

        this.setState({
            blocksLoading: true
        });
        await getAllBlocks(customerConfig).then((response: Response) => {
            if (response.ok) {
                return response.json();
            }
        }).then(async (responseJson: Record<string, any>) => {
            const retrievedItems = responseJson["items"];
            const itemsToLookup: Array<string> = [];

            retrievedItems.forEach((item: Record<string, any>) => {
                itemsToLookup.push(item["thingId"]);
            });

            const itemsMetadata = await getMultiItemMetadata(
                customerConfig,
                itemsToLookup
            ).then((response: Response) => {
                if (response.ok) {
                    return response.json();
                }

            }).then((responseJson: Record<string, any>) => {
                const retrievedItems = responseJson["items"];
                const itemMetadataHash: Record<string, any> = {};
                retrievedItems.forEach((item: Record<string, any>) => {
                    itemMetadataHash[seedIdCheck(customerConfig.name, item.id)] = {
                        "thingName": item.name,
                        "thingImageURL": getImageUrl(item)
                    };
                });

                return itemMetadataHash;
            });

            const blockedItems: BlockedItem[] = [];

            retrievedItems.forEach((item: Record<string, any>) => {
                const itemMetadata = itemsMetadata[item["thingId"]];
                blockedItems.push({
                    thingId: item["thingId"],
                    thingName: itemMetadata["thingName"],
                    thingImageUrl: itemMetadata["thingImageURL"],
                    slotId: item["slotId"],
                    description: item["description"],
                    startDate: item["startDate"],
                    endDate: item["endDate"]
                });
            });

            this.setState({
                blockedItems,
                blocksLoading: false
            });
        }).catch((error) => {
            this.setState({
                blocksLoading: false, error
            });
        });
    }

    private toggleModal(modalType: "addBlock" | "removeBlock" | "editBlock", show: boolean) {
        if (modalType === "addBlock") {
            this.setState({ showAddBlockModal: show });
        } else if (modalType === "removeBlock") {
            this.setState({ showRemoveBlockModal: show });
        } else {
            this.setState({ showEditBlockModal: show });
        }
    }

    private confirmRemoveBlock(blockedItem: BlockedItem) {
        this.setState({
            selectedBlockedItem: blockedItem
        }, () => {
            this.toggleModal("removeBlock", true);
        });
    }

    private confirmEditBlock(blockedItem: BlockedItem) {
        this.setState({
            selectedBlockedItem: blockedItem
        }, () => {
            this.toggleModal("editBlock", true);
        });
    }

    private async removeBlockedItemCallback() {
        const { customerConfig } = this.props;
        const { selectedBlockedItem } = this.state;

        try {
            await deleteEditorial(
                customerConfig,
                selectedBlockedItem!.slotId,
                selectedBlockedItem!.thingId
            );

            setTimeout(() => {
                this.setState({
                    showSuccess: true
                });
            }, 500);
            setTimeout(() => {
                this.setState({
                    showSuccess: false
                });
            }, 4000);
        } catch {
            setTimeout(() => {
                this.setState({
                    showError: true
                });
            }, 500);
            setTimeout(() => {
                this.setState({
                    showError: false
                });
            }, 4000);
        }
        this.toggleModal("removeBlock", false);
        this.getBlockedItems();
    }

    private async editBlockedItemCallback() {
        this.toggleModal("editBlock", false);

        setTimeout(() => {
            this.setState({
                showEditSucces: true
            });
        }, 500);
        setTimeout(() => {
            this.setState({
                showEditSucces: false
            });
        }, 4000);

        this.getBlockedItems();
    }

    private closeSuccess = (): void => {
        this.setState({
            showSuccess: false
        });
    }

    private closeError = (): void => {
        this.setState({
            showError: false
        });
    }

    private renderEmptyOrError() {
        const message = this.state.error
            ? `${this.state.error.name} - ${this.state.error.message}`
            : "No existing blocked items for this customer";

        return (
            <Row className="justify-content-center">
                <Col md={10} style={{ textAlign: "center", paddingTop: "10px" }}>
                    <h5>{message}</h5>
                </Col>
            </Row>
        );
    }

    private renderBlocking(): ReactNode {
        const { blocksLoading, blockedItems, slots } = this.state;
        const { customerConfig } = this.props;

        if (blocksLoading) {
            return (
                <Row className="justify-content-center" style={{ marginTop: "20px" }}>
                    <Spinner animation={"border"} variant={"primary"} />
                </Row>
            );
        }

        if (blockedItems.length > 0) {
            const blockComponents = blockedItems.map((blockedItem: BlockedItem) => {
                return (
                    <Col key={`${blockedItem.thingId}-${blockedItem.slotId}`} md="3" style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                        <BlockingCard
                            blockedItem={blockedItem}
                            slots={slots}
                            customerConfig={customerConfig}
                            confirmRemoveCallback={() => { this.confirmRemoveBlock(blockedItem); }}
                            confirmEditCallback={() => { this.confirmEditBlock(blockedItem); }}
                        />
                    </Col>
                );
            });

            return (
                <Row key={"blocked-items"} className="justify-content-center">
                    {blockComponents}
                </Row>
            );

        } else {
            return this.renderEmptyOrError();
        }
    }

    public render(): ReactNode {
        const { customerConfig, username, groups } = this.props;
        const { showAddBlockModal, showRemoveBlockModal, showEditBlockModal, showSuccess, showEditSucces, showError, selectedBlockedItem, slots } = this.state;

        return (
            <div>
                {selectedBlockedItem && <RemoveBlockingModal
                    showModal={showRemoveBlockModal}
                    closeCallback={() => { this.toggleModal("removeBlock", false); }}
                    removeCallback={() => { this.removeBlockedItemCallback(); }}
                    blockedItem={selectedBlockedItem}
                />}


                {selectedBlockedItem && <EditBlockModal
                    showModal={showEditBlockModal}
                    closeCallback={() => { this.toggleModal("editBlock", false); }}
                    editCallback={() => { this.editBlockedItemCallback(); }}
                    customerConfig={customerConfig}
                    blockedItem={selectedBlockedItem}
                    slots={slots}
                />}

                <BlockingModal
                    customer={customerConfig}
                    showModal={showAddBlockModal}
                    closeCallback={() => { this.toggleModal("addBlock", false); }}
                    addCallback={() => { this.getBlockedItems(); }}
                    username={username}
                    groups={groups}
                    slots={slots}
                />

                <AlertMidRight
                    variant='success'
                    show={showSuccess}
                    onClose={this.closeSuccess}
                    dismissible
                >
                    Item block removed
                </AlertMidRight>

                <AlertMidRight
                    variant='success'
                    show={showEditSucces}
                    onClose={this.closeSuccess}
                    dismissible
                >
                    Item Editted
                </AlertMidRight>

                <AlertMidRight
                    variant='danger'
                    show={showError}
                    onClose={this.closeError}
                    dismissible
                >
                    Error removing item block
                </AlertMidRight>

                <Container className="mw-100" key="Blocking">
                    <StyledRow className="justify-content-center">
                        <AddBlockingCol md={2} className="d-flex justify-content-start">
                            <h4>Blocking</h4>
                        </AddBlockingCol>
                        <AddBlockingCol md={8} className="d-flex justify-content-end">
                            <Button onClick={() => { this.toggleModal("addBlock", true); }}>Add Item Block(s)</Button>
                        </AddBlockingCol>
                    </StyledRow>
                    <Row className="justify-content-center">
                        <Col md={10}>
                            {this.renderBlocking()}
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Blocking;