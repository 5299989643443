import React, { ReactFragment, ReactNode } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { getDefaultRoute, getRoutingForGroup } from "./config/Routing";
import NotFound from "./screens/NotFound";
import Search from "./screens/Search";
import SlotsViewer from "./screens/SlotsViewer";
import FallbackViewer from "./screens/FallbackViewer";
import SlotsEditor from "./screens/SlotsEditor";
import TestTool from "./screens/TestTool";
import LaneBuilder from "./screens/LaneBuilder";
import Metadata from "./screens/Metadata";
import Composer from "./screens/PageBuilder";
import Entities from "./screens/Entities";
import Promotions from "./screens/Promotions";
import Blocking from "./screens/Blocking";
import PagesViewer from "./screens/PagesViewer";
import ModelConfigEditor from "./screens/ModelConfig";
import { THE_FILTER_FULL_AUTH, WAOO_FULL_AUTH, TELENOR_FULL_AUTH, DEMO_FULL_AUTH, RTVE_FULL_AUTH, KAN_FULL_AUTH } from "./constants";
import EntityDetail from "./screens/EntityDetail";
import MultiTagging from "./screens/MultiTagging";
import ModelComparison from "./screens/ModelComparison";
import ModelManager from "./screens/ModelManager";
import PageManager from "./screens/PageManager";
import ExperimentsEditor from "./screens/Experiments";
import Analytics from "./screens/Analytics";
import BrowseView from "./screens/BrowseViewer";
import CollectionsViewer from "./screens/CollectionsViewer";
import SystemHealth from "./screens/SystemHealth";
import ChartsViewer from "./screens/ChartsViewer";
import EventBridge from "./screens/EventBridge";
import PageViewer from "./screens/PageViewer";
import SeedViewer from "./screens/SeedViewer";

type RoutesProps = {
    standalone?: boolean,
    local: boolean,
    groups: string[],
    username: string,
    customerConfig: CustomerConfig,
    setCustomerCallback: (customerName: string) => void
}

export default class Routes extends React.Component<RoutesProps> {

    private getMetadataAccess(): boolean {
        return this.props.groups.includes(THE_FILTER_FULL_AUTH) || this.props.groups.includes(DEMO_FULL_AUTH)
            || this.props.groups.includes(WAOO_FULL_AUTH) || this.props.groups.includes(TELENOR_FULL_AUTH)
            || this.props.groups.includes(RTVE_FULL_AUTH) || this.props.groups.includes(KAN_FULL_AUTH) ;
    }


    private getRouteDefinition(routePath: string, key: string): JSX.Element | undefined {
        switch (routePath) {
            case "/tester":
                return (
                    <Route path={routePath} key={key} component={() =>
                        <TestTool
                            local={this.props.local}
                            groups={this.props.groups}
                            metadataAccess={this.getMetadataAccess()}
                            username={this.props.username}
                            customerConfig={this.props.customerConfig}
                        />
                    } />
                );
            case "/lanebuilder":
                return (
                    <Route path={routePath} key={key} component={() =>
                        <LaneBuilder
                            groups={this.props.groups}
                            standalone={this.props.standalone}
                            username={this.props.username}
                            customerConfig={this.props.customerConfig}
                        />
                    } />
                );
            case "/metadata":
                return (
                    <Route path={routePath} key={key} component={() =>
                        <Metadata
                            standalone={this.props.standalone}
                            username={this.props.username}
                            groups={this.props.groups}
                            setCustomerCallback={this.props.setCustomerCallback}
                        />
                    } />
                );
            case "/search":
                return (
                    <Route path={routePath} key={key} component={() =>
                        <Search
                            standalone={this.props.standalone}
                            groups={this.props.groups}
                            metadataAccess={this.getMetadataAccess()}
                            username={this.props.username}
                            customerConfig={this.props.customerConfig}
                        />
                    } />
                );
            case "/slots":
                return (
                    <Route path={routePath} key={key} component={() =>
                        <SlotsViewer
                            standalone={this.props.standalone}
                            groups={this.props.groups}
                            metadataAccess={this.getMetadataAccess()}
                            username={this.props.username}
                            customerConfig={this.props.customerConfig}
                        />
                    } />
                );
            case "/fallbackviewer":
                return (
                    <Route path={routePath} key={key} component={() =>
                        <FallbackViewer
                            standalone={this.props.standalone}
                            groups={this.props.groups}
                            metadataAccess={this.getMetadataAccess()}
                            username={this.props.username}
                            customerConfig={this.props.customerConfig}
                        />
                    } />
                );
            case "/slotseditor":
                return (
                    <Route path={routePath} key={key} component={() =>
                        <SlotsEditor
                            standalone={this.props.standalone}
                            groups={this.props.groups}
                            metadataAccess={this.getMetadataAccess()}
                            username={this.props.username}
                            customerConfig={this.props.customerConfig}
                        />
                    } />
                );
            case "/experiments":
                return (
                    <Route path={routePath} key={key} component={() =>
                        <ExperimentsEditor
                            standalone={this.props.standalone}
                            groups={this.props.groups}
                            username={this.props.username}
                            customerConfig={this.props.customerConfig}
                        />
                    } />
                );
            case "/pagebuilder":
                return (
                    <Route path={routePath} key={key} component={() =>
                        <Composer
                            standalone={this.props.standalone}
                            groups={this.props.groups}
                            username={this.props.username}
                            customerConfig={this.props.customerConfig}
                        />
                    } />
                );
            case "/modelcomparison":
                return (
                    <Route path={routePath} key={key} component={() =>
                        <ModelComparison
                            standalone={this.props.standalone}
                            groups={this.props.groups}
                            username={this.props.username}
                            customerConfig={this.props.customerConfig}
                        />
                    } />
                );
            case "/entities":
                return (
                    <Route path={routePath} key={key} component={() =>
                        <Entities
                            standalone={this.props.standalone}
                            groups={this.props.groups}
                            username={this.props.username}
                            customerConfig={this.props.customerConfig}
                        />
                    } />
                );

            case "/multitagging":
                return (
                    <Route path={routePath} key={key} component={() =>
                        <MultiTagging
                            standalone={this.props.standalone}
                            groups={this.props.groups}
                            metadataAccess={this.getMetadataAccess()}
                            username={this.props.username}
                            customerConfig={this.props.customerConfig}
                        />
                    } />
                );
            case "/promotions":
                return (
                    <Route path={routePath} key={key} component={() =>
                        <Promotions
                            standalone={this.props.standalone}
                            groups={this.props.groups}
                            username={this.props.username}
                            customerConfig={this.props.customerConfig}
                            setCustomerCallback={this.props.setCustomerCallback}
                        />
                    } />
                );
            case "/blocking":
                return (
                    <Route path={routePath} key={key} component={() =>
                        <Blocking
                            standalone={this.props.standalone}
                            groups={this.props.groups}
                            username={this.props.username}
                            customerConfig={this.props.customerConfig}
                        />
                    } />
                );
            case "/pages":
                return (
                    <Route path={routePath} key={key} component={() =>
                        <PagesViewer
                            standalone={this.props.standalone}
                            groups={this.props.groups}
                            metadataAccess={this.getMetadataAccess()}
                            username={this.props.username}
                            customerConfig={this.props.customerConfig}
                        />
                    } />
                );
            case "/modelconfig":
                return (
                    <Route path={routePath} key={key} component={() =>
                        <ModelConfigEditor
                            standalone={this.props.standalone}
                            groups={this.props.groups}
                            username={this.props.username}
                            customerConfig={this.props.customerConfig}
                        />
                    } />
                );
            case "/modelmanager":
                return (
                    <Route path={routePath} key={key} component={() =>
                        <ModelManager
                            standalone={this.props.standalone}
                            groups={this.props.groups}
                            username={this.props.username}
                            customerConfig={this.props.customerConfig}
                        />
                    } />
                );
            case "/pagemanager":
                return (
                    <Route path={routePath} key={key} component={() =>
                        <PageManager
                            standalone={this.props.standalone}
                            groups={this.props.groups}
                            username={this.props.username}
                            customerConfig={this.props.customerConfig}
                        />
                    } />
                );
            case "/browseview":
                return (
                    <Route path={routePath} key={key} component={() =>
                        <BrowseView
                            standalone={this.props.standalone}
                            groups={this.props.groups}
                            metadataAccess={this.getMetadataAccess()}
                            username={this.props.username}
                            customerConfig={this.props.customerConfig}
                        />
                    } />
                );
            case "/entitiesdetail":
                return (
                    <Route path={routePath} key={key} component={() =>
                        <EntityDetail
                            standalone={this.props.standalone}
                            groups={this.props.groups}
                            username={this.props.username}
                            setCustomerCallback={this.props.setCustomerCallback}
                        />
                    } />
                );
            case "/analytics":
                return (
                    <Route path={routePath} key={key} component={() =>
                        <Analytics
                            standalone={this.props.standalone}
                            groups={this.props.groups}
                            username={this.props.username}
                            customerConfig={this.props.customerConfig}
                        />
                    } />
                );
            case "/collections":
                return (
                    <Route path={routePath} key={key} component={() =>
                        <CollectionsViewer
                            standalone={this.props.standalone}
                            groups={this.props.groups}
                            username={this.props.username}
                            customerConfig={this.props.customerConfig}
                        />
                    } />
                );
            case "/systemhealth":
                return (
                    <Route path={routePath} key={key} component={() =>
                        <SystemHealth
                            standalone={this.props.standalone}
                            groups={this.props.groups}
                            username={this.props.username}
                            customerConfig={this.props.customerConfig}
                        />
                    } />
                );
            case "/chartsviewer":
                return (
                    <Route path={routePath} key={key} component={() =>
                        <ChartsViewer
                            standalone={this.props.standalone}
                            groups={this.props.groups}
                            username={this.props.username}
                            customerConfig={this.props.customerConfig}
                        />
                    } />
                );

            case "/modelviewer":
                return (
                    <Route path={routePath} key={key} component={() =>
                        <SlotsViewer
                            standalone={this.props.standalone}
                            groups={this.props.groups}
                            username={this.props.username}
                            customerConfig={this.props.customerConfig}
                            modelViewer={true}
                        />
                    } />
                );

            case "/eventbridge":
                return (
                    <Route path={routePath} key={key} component={() =>
                        <EventBridge
                            standalone={this.props.standalone}
                            groups={this.props.groups}
                            username={this.props.username}
                            customerConfig={this.props.customerConfig}
                        />
                    } />
                );

            case "/pageviewer":
                return (
                    <Route path={routePath} key={key} component={() =>
                        <PageViewer
                            standalone={this.props.standalone}
                            groups={this.props.groups}
                            username={this.props.username}
                            customerConfig={this.props.customerConfig}
                        />
                    } />
                );


            case "/seedviewer":
                return (
                    <Route path={routePath} key={key} component={() =>
                        <SeedViewer
                            standalone={this.props.standalone}
                            groups={this.props.groups}
                            username={this.props.username}
                            customerConfig={this.props.customerConfig}
                        />
                    } />
                );

            default:
                break;
        }
    }


    private getRoutes(): ReactFragment {

        const routingConfig: RouteConfig[] = getRoutingForGroup(this.props.groups);
        const routes: JSX.Element[] = [];

        let routePath: JSX.Element | undefined = <></>;

        routingConfig.forEach((route: RouteConfig, i: number) => {
            if (this.props.groups.some(g => route.groups.includes(g))) {
                if (route.children) {
                    route.children.forEach((childRoute: RouteConfig, j: number) => {
                        routePath = this.getRouteDefinition(childRoute.path, childRoute.path.replace("\\", ""));
                        if (routePath) routes.push(routePath);
                    });
                } else {
                    routePath = this.getRouteDefinition(route.path, route.path.replace("\\", ""));
                    if (routePath) routes.push(routePath);
                }
            }
        });

        return routes;
    }

    public render(): ReactNode {
        return (
            <Switch>
                <Route exact path="/">
                    <Redirect to={getDefaultRoute(this.props.groups)} />
                </Route>
                {this.getRoutes()}
                <Route component={NotFound} />
            </Switch>
        );
    }
}
