import React, { ReactNode } from "react";
import { Button, Container, Form, InputGroup, Modal, OverlayTrigger, Row, Spinner, Table, Tooltip } from "react-bootstrap";
import styled from "styled-components";
import Logger from "../../utils/Logger";
import { entitySearch, createOrUpdateEntities, getHostURL } from "../../utils/Requests";
import { CentralCol } from "../../components/SharedStyled";

const EditorialGroupsRow = styled(Row)`
    margin-top: 10px;
`;

type ListTabProperties = {
    customerConfig: CustomerConfig,
    portalUsername: string,
    portalGroups: string[],
    selectCallback: (editorialID: string, editorialName: string) => void,
}

type ListTabState = {
    searchResults: Array<any>,
    error: Error | null,
    loading: boolean,
    loaded: boolean,
    showModal: boolean,
    newEditorialGroupName?: string
}


export default class ListTab extends React.Component<ListTabProperties, ListTabState> {

    constructor(props: ListTabProperties) {
        super(props);

        this.state = {
            searchResults: [],
            error: null,
            loading: false,
            loaded: false,
            showModal: false
        };
    }

    public componentDidMount(): void {
        this.requestItems();
    }

    public reset = (): void => {
        this.setState({
            searchResults: [],
            error: null,
            loading: false,
            loaded: false,
            showModal: false
        }, () => {
            this.requestItems();
        });
    }

    private update = (): void => {
        this.setState({
            searchResults: [],
            loading: true,
            loaded: false
        }, () => {
            setTimeout(() => { this.requestItems(); }, 500);
        });
    }

    private handleError(error: Error) {
        Logger.error(error);
        this.setState({
            loading: false,
            loaded: true,
            error
        });
    }

    private handleEntityModalShow = () => {
        this.setState({
            showModal: true,
            newEditorialGroupName: undefined
        });
    }

    private handleEntityModalClose = () => {
        this.setState({
            showModal: false
        });
        this.update();
    }

    private selectCallback(editorialId: string, editorialName: string) {
        if (this.props.selectCallback) {
            this.props.selectCallback(editorialId, editorialName);
        }
    }

    private renderEditorialGroupList(): ReactNode {
        const {error, loading, loaded, searchResults} = this.state;

        if (error && error) {
            return <Row className="justify-content-center">
                Error - {error!.message}
            </Row>;
        }

        if (loading) {
            return <Spinner animation="border" variant="primary" />;
        }

        if (!loading && !loaded) {
            return <Row className="justify-content-center">
                Results will appear here
            </Row>;
        } else {
            return <Row>
                <Table striped bordered responsive size="sm">
                    <thead>
                        <tr>
                            <th>Lane Name</th>
                            <th>Usage Link</th>
                            <th>Created</th>
                            <th>Created By</th>
                        </tr>
                    </thead>
                    <tbody>
                        {searchResults.map(row => (
                            <tr key={row.id}>
                                <td key={row.id + "name"}>
                                    <Button variant="link" onClick={() => {
                                        this.selectCallback(row.id, row.name);
                                    }}>
                                    {row.name}
                                    </Button>
                                </td>
                                <td key={row.id + "link"}>
                                    {this.createEditorialGroupLink(row["id"])}
                                </td>
                                <td key={row.id + "dateCreated"}>
                                    {row.dateCreated.replace("T", " ")}
                                </td>
                                <td key={row.id + "createdBy"}>
                                    {row.createdBy}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Row>;
        }
    }

    private createEditorialGroupLink(editorialGroupID: string): string {
        const editorialLaneSlot = this.props.customerConfig.entitySlot;

        return `${getHostURL(this.props.customerConfig)}/slots/${editorialLaneSlot}/items?entityId=${editorialGroupID}`;
    }

    private async requestItems(): Promise<void>  {
        try {
            const searchResults = await entitySearch(
                "group",
                "",
                this.props.customerConfig.slug
            );

            this.setState({
                searchResults: searchResults,
                loading: false,
                loaded: true
            });
        } catch (err) {
            if (err instanceof Error) {
                this.handleError(err);
            }
        }
    }

    private setNewEditorialGroupName = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        this.setState({
            newEditorialGroupName: event.target.value
        });
    }

    private createNewEditorialGroup = async () => {
        if (this.state.newEditorialGroupName) {
            const editorialGroupEntity: Entity = {
                name: this.state.newEditorialGroupName,
                type: "group",
                alternateName: [],
                description: "",
                notes: "",
                namespace: this.props.customerConfig.slug
            };

            await createOrUpdateEntities([editorialGroupEntity]).then(() => {
                this.handleEntityModalClose();
            });
        }
    }

    public render(): ReactNode {
        return (
            <>
            <Modal show={this.state.showModal} onHide={this.handleEntityModalClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Create New Lane</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={(event) => {event.preventDefault();}}>
                        <Form.Group controlId="formEditorialGroup">
                            <Form.Label>Lane Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Lane Name"
                                onChange={this.setNewEditorialGroupName}
                                defaultValue={this.state.newEditorialGroupName}
                                autoComplete="off"
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={this.handleEntityModalClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={this.createNewEditorialGroup}>
                    Create New Group
                </Button>
                </Modal.Footer>
            </Modal>
            <Container className="mw-100">
                <EditorialGroupsRow className="justify-content-center" style={{overflowY: "scroll", height: "80vh"}}>
                    <CentralCol md={11}>
                        {this.renderEditorialGroupList()}

                        <InputGroup className="justify-content-end" style={{width: "100%"}}>
                            <OverlayTrigger overlay={<Tooltip id="Create">Create New Lane</Tooltip>}>
                                <Button variant="primary" type="button" onClick={()=> {this.handleEntityModalShow();}}>
                                    Create New Lane
                                </Button>
                            </OverlayTrigger>
                        </InputGroup>
                    </CentralCol>
                </EditorialGroupsRow>
            </Container>
            </>
        );
    }

}
