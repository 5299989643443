import React, { ReactNode } from "react";
import { Button, Col, Container, Form, Image, InputGroup, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap";
import {
    Bookmark as PresetIcon
} from "react-bootstrap-icons";
import styled from "styled-components";

import MetadataCarousel from "../components/MetadataCarousel";
import Branding from "../config/Branding";
import { getHostURL, getPages, makePageRequest } from "../utils/Requests";
import * as utils from "../utils/Utils";
import * as constants from "../constants";
import Logger from "../utils/Logger";
import RandomField from "../components/fields/RandomField";
import { CarouselRow, CentralCol } from "../components/SharedStyled";

const ImageCol = styled(Col)`
    margin-right: 20px;
`;

const ScaledImage = styled(Image)`
    height: unset;
    width: unset;
    max-width: 250px;
    max-height: 95px;
`;

const PagesViewerMainRow = styled(Row)`
    background: ${Branding.transparentBackground};
    top: ${props => props.standalone ? "30px" : "63px"};
`;

const PagesViewerFormRow = styled(Form.Row)`
    margin-top: 10px;
    margin-bottom: 20px;
`;

const PagesViewerElementRow = styled(Form.Row)`
    margin-bottom: 5px;
`;

const PagesViewerFormCol = styled(Col)`
    padding-left: 0 !important;
`;

type PagesViewerState = {
    customer: CustomerConfig,
    availablePages: Array<Record<string, any>>,
    selectedPage: Record<string, any>,
    userId: string,
    slotResults: Array<Record<string, any>>,
    error: Error | null,
    isLoaded: boolean
    isLoading: boolean,
    presetNumber: number
}

type PagesViewerProperties = {
    standalone?: boolean,
    metadataAccess?: boolean,
    groups: string[],
    username: string,
    customerConfig: CustomerConfig
}

export default class PagesViewer extends React.Component<PagesViewerProperties, PagesViewerState> {

    private timeoutId: number | null;

    constructor(props: PagesViewerProperties) {
        super(props);
        this.timeoutId = null;

        this.state = {
            customer: this.props.customerConfig,
            availablePages: [],
            selectedPage: {},
            presetNumber: 0,
            userId: "1234",
            slotResults: [],
            isLoading: false,
            error: null,
            isLoaded: false
        };
    }

    public componentDidMount(): void {
        this.getAvailablePages(this.state.customer).then(this.update);
    }

    private async getAvailablePages(customer: CustomerConfig): Promise<void> {
        await getPages(customer).then((response: Array<Record<string, any>>) => {
            this.setState({
                availablePages: response,
                selectedPage: response[0]
            });
        });

    }

    private update = (): void => {
        this.setState({
            isLoading: true,
            isLoaded: false
        }, this.requestItems);
    }

    private handleError(error: Error) {
        Logger.error(error);
        this.setState({
            isLoading: false,
            isLoaded: true,
            error
        });
    }

    private getUrl = (includeParams = true) => {
        const selectedPage = this.state.selectedPage;
        let url = `${getHostURL(this.state.customer)}/pages/${selectedPage.pageId}`;

        if (includeParams) {
            url += `?_debug_metadata=true&ignore=testtool&userId=${encodeURIComponent(this.state.userId.trim())}`;
            url = utils.addPortalUserInfoToUrl(url, this.props.username, this.props.groups);
        }

        return url;
    }

    public async requestItems(): Promise<void> {
        await makePageRequest(this.getUrl(true))
            .then((response) => {
                if (response.status !== 200) {
                    if (response.status === 404) {
                        throw new Error("404: Not Found");
                    } else {
                        const contentType = response.headers.get("content-type");
                        if (contentType && contentType.indexOf("application/json") !== -1) {
                            response.json().then((error) => {
                                this.handleError(error);
                            });
                        } else {
                            response.text().then((error) => {
                                this.handleError(new Error(error));
                            });
                        }
                    }
                } else {
                    response.json().then(async (result) => {
                        if (result) {
                            const code = result.code;
                            if (code === 200) {
                                this.setState({
                                    isLoading: false,
                                    isLoaded: true,
                                    slotResults: result.results,
                                    error: null
                                });
                            } else {
                                this.setState({
                                    isLoading: false,
                                    isLoaded: true,
                                    error: Error(result.message)
                                });
                            }
                        }
                    });
                }
            }).catch((error) => {
                this.handleError(error);
            });
    }

    private checkKey = (event: React.KeyboardEvent): void => {
        if (event.key === "Enter" || event.key === "NumpadEnter") {
            if (this.timeoutId) {
                clearTimeout(this.timeoutId);
            }
            this.update();
        }
        else if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    }

    private setUserId = (callbackValue: string, submit: boolean): void => {
        this.setState({
            userId: callbackValue
        }, () => {
            if (submit) {
                this.update();
            }
        });
    }

    private setPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const page: Record<string, any> = this.state.availablePages[parseInt(event.target.value)];

        this.setState({
            selectedPage: page,
            presetNumber: parseInt(event.target.value),
            userId: "1234",
            slotResults: [],
            isLoading: false,
            isLoaded: false,
            error: null
        }, this.update);
    }

    private renderPageResults(): ReactNode {
        const { error, isLoading, isLoaded } = this.state;

        if (error) {
            return <Row className="justify-content-center">
                Error - {error.message}
            </Row>;
        }

        if (isLoading) {
            return <Spinner animation="border" variant="primary" />;
        }

        if (!isLoading && !isLoaded) {
            return <Row className="justify-content-center">
                Results will appear here
            </Row>;
        } else {
            const slotCarousels: Array<ReactNode> = [];
            this.state.slotResults.forEach((slot: Record<string, any>) => {
                const slotTitle = slot["title"];
                const slotItems = slot["items"]["items"];

                slotCarousels.push(
                    <CarouselRow key={slot.title}>
                        <Col md style={{ textAlign: "left" }}>
                            <h5 style={{ paddingLeft: "40px" }} className="mx-auto">{slotTitle}</h5>
                            {slotItems &&
                                <MetadataCarousel items={slotItems}
                                    customer={this.state.customer}
                                    hideInfoIcon
                                    modal
                                    username={this.props.username}
                                    groups={this.props.groups}
                                />
                            }
                        </Col>
                    </CarouselRow>
                );
            });

            return <>{slotCarousels}</>;
        }
    }

    public render(): ReactNode {
        return (
            <Container className="mw-100">
                <PagesViewerMainRow className="justify-content-center sticky-banner" standalone={this.props.standalone ? 1 : 0}>
                    <CentralCol md>
                        <Form className="PagesViewerSettings">
                            <PagesViewerFormRow className="justify-content-center">
                                <ImageCol md={2} className="d-none d-sm-block my-auto">
                                    <Row className="justify-content-center">
                                        {this.props.customerConfig.logoLocation &&
                                        <ScaledImage
                                            src={this.props.customerConfig.logoLocation}
                                            onError={(event) => { utils.getErrorLogo(event); }}
                                        />}
                                    </Row>
                                </ImageCol>
                                <Col md={4}>
                                    <PagesViewerElementRow>
                                        <InputGroup>
                                            <InputGroup.Prepend>
                                                <OverlayTrigger
                                                    placement="left"
                                                    overlay={<Tooltip id="preset-tooltip">Preset</Tooltip>}
                                                >
                                                    <InputGroup.Text><PresetIcon /></InputGroup.Text>
                                                </OverlayTrigger>
                                            </InputGroup.Prepend>
                                            <Form.Control
                                                as="select"
                                                id="dropdown-preset"
                                                title="Preset"
                                                size="sm"
                                                onChange={this.setPage}
                                                style={{ "padding": "0.375rem 0.375rem 0.375rem 0.1rem" }}
                                                value={this.state.presetNumber}>
                                                {this.state.availablePages.map((page, i) => {
                                                    return (
                                                        <option value={i} key={i}>
                                                            {page.name} - ({page.pageId})
                                                        </option>
                                                    );
                                                })}

                                            </Form.Control>
                                        </InputGroup>
                                    </PagesViewerElementRow>
                                    <PagesViewerElementRow>
                                        <PagesViewerFormCol md={10}>
                                            <RandomField
                                                customer={this.state.customer}
                                                type={constants.USER}
                                                includeIcon
                                                small
                                                initialValue={this.state.userId}
                                                onChangeCallback={this.setUserId}
                                                onKeyUpCallback={this.checkKey}
                                            />
                                        </PagesViewerFormCol>
                                        <PagesViewerFormCol md={2} className="d-flex justify-content-end" style={{ paddingRight: "0px" }}>
                                            <Button variant="primary" size="sm" type="button" onClick={this.update}>
                                                Submit
                                            </Button>
                                        </PagesViewerFormCol>
                                    </PagesViewerElementRow>
                                </Col>
                            </PagesViewerFormRow>
                        </Form>
                    </CentralCol>
                </PagesViewerMainRow>
                <Row className="justify-content-center">
                    <CentralCol md>
                        {this.renderPageResults()}
                    </CentralCol>
                </Row>
            </Container>
        );
    }
}