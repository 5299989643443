import React, { ReactNode } from "react";
import { Button, Col, Container, Form, Image, InputGroup, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap";
import { FileEarmark as PageIcon } from "react-bootstrap-icons";
import styled from "styled-components";

import Branding from "../config/Branding";
import { getHostURL, getItemMetadata, getPageDomainInformation, makePageRequest } from "../utils/Requests";
import * as utils from "../utils/Utils";
import * as constants from "../constants";
import Logger from "../utils/Logger";
import RandomField from "../components/fields/RandomField";
import MetadataCarousel from "../components/MetadataCarousel";
import SeedAndUserHistoryPanel from "../components/SeedAndUserHistoryPanel";
import { RouteComponentProps, withRouter } from "react-router-dom";
import SeedInfo from "../components/SeedInfo";
import { CarouselRow, CentralCol, SectionHeader } from "../components/SharedStyled";

const ResultsCol = styled(Col)`
    top: 10px;
    text-align:center;
`;

const ScaledImage = styled(Image)`
    height: 60px;
    width: unset;
    max-width: 240px;
    margin-top: 8px;
    margin-bottom: 8px;
`;

const PageViewerMainRow = styled(Row)`
    background: ${Branding.transparentBackground};
    top: ${props => props.standalone ? "30px" : "63px"};
`;

const PageViewerFormRow = styled(Row)`
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: -5px;
`;

const PageViewerElementRow = styled(Row)`
    margin-bottom: 5px;
`;

const PageViewerFormCol = styled(Col)`
    padding-left: 0 !important;
`;

type PageViewerState = {
    availablePresets?: Preset[],
    availablePageInfo: Array<Record<string, any>>,
    selectedPage?: Record<string, any>,
    selectedPageIndex: number,
    userId: string,
    seedId: string | undefined,
    pageResponse: Array<Record<string, any>>,
    error: Error | null,
    isLoaded: boolean
    isLoading: boolean,
    seedItem?: Record<string, any>,
    showSeedAndHistoryPanel: boolean
}

type PageViewerProps = {
    standalone?: boolean,
    metadataAccess?: boolean,
    groups: string[],
    username: string
    customerConfig: CustomerConfig,
}

interface PageViewerProperties extends PageViewerProps, RouteComponentProps { }

class PageViewer extends React.Component<PageViewerProperties, PageViewerState> {

    private timeoutId: number | null;

    constructor(props: PageViewerProperties) {
        super(props);
        this.timeoutId = null;

        this.state = {
            availablePageInfo: [],
            userId: "",
            seedId: "",
            pageResponse: [],
            isLoading: false,
            error: null,
            isLoaded: false,
            showSeedAndHistoryPanel: false,
            selectedPageIndex: 0
        };
    }

    public componentDidMount(): void {
        this.requestPageDomainInfo();
    }

    private async requestPageDomainInfo(): Promise<void> {
        await getPageDomainInformation(this.props.customerConfig).then((response: Array<Record<string, any>>) => {
            this.setState({
                availablePageInfo: response,
                selectedPageIndex: 0,
                selectedPage: response[0]
            }, this.requestItems);
        });
    }


    private update = (): void => {
        this.setState({
            isLoading: true,
            isLoaded: false
        }, () => {
            this.requestItems();
            if (this.state.seedId) this.getSeedInfo();
        });
    }

    private handleError(error: Error) {
        Logger.error(error);
        this.setState({
            isLoading: false,
            isLoaded: true,
            error
        });
    }

    private getUrl = () => {
        const selectedPage = this.state.selectedPage;
        let url = `${getHostURL(this.props.customerConfig)}/pages/${selectedPage!.topic}`;
        url += `?_debug_metadata=true&ignore=testtool&userId=${encodeURIComponent(this.state.userId.trim())}&seedIds=${encodeURIComponent(this.state.seedId!.trim())}`;
        url = utils.addPortalUserInfoToUrl(url, this.props.username, this.props.groups);

        return url;
    }

    public async requestItems(): Promise<void> {
        if (this.state.selectedPage) {
            await makePageRequest(this.getUrl())
                .then((response) => {
                    if (response.status !== 200) {
                        if (response.status === 404) {
                            throw new Error("404: Not Found");
                        } else {
                            const contentType = response.headers.get("content-type");
                            if (contentType && contentType.indexOf("application/json") !== -1) {
                                response.json().then((error) => {
                                    this.handleError(error);
                                });
                            } else {
                                response.text().then((error) => {
                                    this.handleError(new Error(error));
                                });
                            }
                        }
                    } else {
                        response.json().then((pageResponse) => {
                            this.setState({
                                pageResponse: pageResponse["recommendations"],
                                isLoaded: true,
                                isLoading: false,
                                error: null
                            });
                        });
                    }
                }).catch((error) => {
                    this.handleError(error);
                });
        }
    }

    private checkKey = (event: React.KeyboardEvent): void => {
        if (event.key === "Enter" || event.key === "NumpadEnter") {
            if (this.timeoutId) {
                clearTimeout(this.timeoutId);
            }
            this.update();
        }
        else if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    }

    private setUserId = (callbackValue: string, submit: boolean): void => {
        this.setState({
            userId: callbackValue
        }, () => {
            if (submit) {
                this.update();
            }
        });
    }

    private setSeedId = (callbackValue: string, submit: boolean): void => {
        this.setState({
            seedId: callbackValue
        }, () => {
            if (submit) {
                this.update();
            }
        });
    }

    private setPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (this.state.availablePageInfo) {
            const page: Record<string, any> = this.state.availablePageInfo[parseInt(event.target.value)];

            const userId = this.state.userId;
            const seedId = this.state.seedId;

            this.setState({
                selectedPage: page,
                selectedPageIndex: parseInt(event.target.value),
                userId,
                seedId,
                pageResponse: [],
                isLoading: false,
                isLoaded: false,
                error: null,
                seedItem: undefined
            }, this.update);
        }
    }

    private renderResults(): ReactNode {
        const { error, isLoading, isLoaded } = this.state;

        if (error) {
            return <Row className="justify-content-center">
                Error - {error.message}
            </Row>;
        }

        if (isLoading) {
            return <Spinner animation="border" variant="primary" />;
        }

        if (!isLoading && !isLoaded) {
            return <Row className="justify-content-center">
                Results will appear here
            </Row>;
        } else if (this.state.pageResponse) {
            const slotCarousels: Array<ReactNode> = [];
            this.state.pageResponse.forEach((model: Record<string, any>) => {
                const modelTitle = model["model"];
                const modelResponseId = model["response"]["responseId"];
                const modelItems: Record<string, any>[] = model["response"]["items"];

                slotCarousels.push(
                    <CarouselRow key={modelResponseId}>
                        <Col md style={{ textAlign: "left" }}>
                            <h5 style={{ paddingLeft: "0px" }} className="mx-auto">{modelTitle}</h5>
                            {modelItems &&
                                <MetadataCarousel items={modelItems}
                                    customer={this.props.customerConfig}
                                    hideInfoIcon
                                    modal
                                    username={this.props.username}
                                    groups={this.props.groups}
                                />
                            }
                        </Col>
                    </CarouselRow>
                );
            });

            return <>{slotCarousels}</>;
        }
    }

    private getSeedInfo = async (): Promise<void> => {
        if (this.props.customerConfig && this.state.seedId) {
            const lookupSeedId: string | undefined = this.state.seedId;

            await getItemMetadata(this.props.customerConfig, lookupSeedId).then((response) => {
                if (response.ok) {
                    return response.json().then((response) => {
                        this.setState({
                            seedItem: response.items[0]
                        });
                    });
                } else {
                    return Promise.reject(response);
                }
            });
        } else {
            this.setState({
                seedItem: undefined
            });
        }
    }

    private toggleSeedAndHistoryPanel = (): void => {
        this.setState({
            showSeedAndHistoryPanel: !this.state.showSeedAndHistoryPanel
        });
    }

    public render(): ReactNode {
        return (
            <Container className="mw-100" style={{margin: "5px"}}>
                <PageViewerMainRow className="justify-content-center" standalone={this.props.standalone ? 1 : 0}>
                    <Col md={3}>
                            <PageViewerFormRow className="justify-content-center">
                                <CentralCol>
                                    <Row className="justify-content-center">
                                        {this.props.customerConfig.logoLocation &&
                                        <ScaledImage
                                            src={this.props.customerConfig.logoLocation}
                                            onError={(event) => { utils.getErrorLogo(event); }}
                                        />}
                                    </Row>
                                    <Row className="justify-content-center">
                                        <b>Page Viewer</b>
                                    </Row>
                                    <PageViewerElementRow>
                                        <InputGroup>
                                            <InputGroup.Prepend>
                                                <OverlayTrigger
                                                    placement="auto"
                                                    overlay={<Tooltip id="page-tooltip">Page</Tooltip>}
                                                >
                                                    <InputGroup.Text><PageIcon /></InputGroup.Text>
                                                </OverlayTrigger>
                                            </InputGroup.Prepend>
                                            {this.state.availablePageInfo.length > 0 && <Form.Control
                                                as="select"
                                                id="dropdown-page"
                                                title="Page"
                                                size="sm"
                                                onChange={this.setPage}
                                                style={{ "padding": "0.375rem 0.375rem 0.375rem 0.1rem" }}
                                                value={this.state.selectedPageIndex}>
                                                {this.state.availablePageInfo.map((page, i) => {
                                                    return (
                                                        <option value={i} key={i}>
                                                            {page.page_name}
                                                        </option>
                                                    );
                                                })}

                                            </Form.Control>}
                                            {this.state.availablePageInfo.length === 0 && <Form.Control
                                                type="string"
                                                id="dropdown-page"
                                                size="sm"
                                                disabled
                                                style={{ "padding": "0.375rem 0.375rem 0.375rem 0.1rem" }}
                                                value={"No Pages exist for this customer"}>
                                            </Form.Control>}
                                        </InputGroup>
                                    </PageViewerElementRow>
                                    <PageViewerElementRow>
                                        <RandomField
                                            customer={this.props.customerConfig}
                                            type={constants.USER}
                                            small
                                            includeIcon
                                            initialValue={this.state.userId}
                                            onChangeCallback={this.setUserId}
                                            onKeyUpCallback={this.checkKey}
                                        />
                                    </PageViewerElementRow>
                                    <PageViewerElementRow>
                                        <RandomField
                                            customer={this.props.customerConfig}
                                            type={constants.SEED}
                                            includeIcon
                                            small
                                            initialValue={this.state.seedId}
                                            onChangeCallback={this.setSeedId}
                                            onKeyUpCallback={this.checkKey}
                                            showName={false}
                                            clearable
                                        />
                                    </PageViewerElementRow>
                                    <PageViewerElementRow>
                                        <PageViewerFormCol style={{ textAlign: "right", paddingRight: "0px" }}>
                                            <Button variant="primary" size="sm" type="button" onClick={this.update}>
                                                Submit
                                            </Button>
                                        </PageViewerFormCol>
                                    </PageViewerElementRow>
                                </CentralCol>
                            </PageViewerFormRow>
                            <PageViewerFormRow>
                            {this.state.seedItem && !this.state.showSeedAndHistoryPanel &&
                                <Col>
                                    <Row style={{textAlign: "center"}}>
                                        <CentralCol>
                                            <SectionHeader>Seed Info</SectionHeader>
                                        </CentralCol>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <SeedInfo
                                                customerConfig={this.props.customerConfig}
                                                seedItem={this.state.seedItem}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            }
                            </PageViewerFormRow>
                    </Col>
                    <ResultsCol md={9} className="justify-content-center" style={{ minHeight: "85vh" }}>
                        {this.state.selectedPage && this.renderResults()}
                        {this.state.availablePageInfo.length === 0 &&
                            <p>No pages exist for this customer, try creating some via the <a href="/pagemanager">Page Manager</a></p>
                        }
                    </ResultsCol>
                </PageViewerMainRow>
                <SeedAndUserHistoryPanel
                    customerConfig={this.props.customerConfig}
                    userId={this.state.userId}
                    seedItem={this.state.seedItem}
                    showPanel={this.state.showSeedAndHistoryPanel}
                    showHidePanelCallback={this.toggleSeedAndHistoryPanel}
                />
            </Container>
        );
    }
}

export default withRouter(PageViewer);