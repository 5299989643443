import styled from "styled-components";
import Branding from "../config/Branding";
import { Alert, Col, Form, InputGroup, Row } from "react-bootstrap";

export const AlertTopRight = styled(Alert)`
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 10;
    min-width: 200px;
    height: 50px;
`;

export const AlertMidRight = styled(Alert)`
    position: absolute;
    top: 100px;
    right: 5px;
    z-index: 10;
    min-width: 200px;
    height: 50px;
`;

export const CentralCol = styled(Col)`
    text-align: center;
`;

export const CarouselRow = styled(Row)`
    background-color: ${Branding.superDarkBackground};
    border-radius: 3px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 5px 5px;
`;

export const FieldIcon = styled(InputGroup.Text)`
    background: ${Branding.black};
    padding-left: 10px;
    padding-right: 10px;
`;

export const FieldName = styled(InputGroup.Text)`
    background: ${Branding.black};
    min-width: 75px;
`;

export const FieldNamePadded = styled(InputGroup.Text)`
    background: ${Branding.black};
    padding-left: 10px;
    padding-right: 10px;
`;

export const FieldValue = styled(Form.Control)`
    padding: 0.375rem 0.375rem;
`;

export const FormEntry = styled.div`
    padding: 2pt;
    width: 100%;
    text-align: center;
`;

export const FormRow = styled(Row)`
    padding-top: 5px;
    padding-bottom: 5px;
`;

export const SectionHeader = styled.h5`
    margin-top: 10px;
    text-align: center;
    font-size: 1.2rem;
`;