import React, { ReactNode, RefObject } from "react";
import { Button, Image, OverlayTrigger, Popover, Spinner, Tooltip } from "react-bootstrap";
import styled from "styled-components";
import * as Icons from "react-bootstrap-icons";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import moment from "moment";

import * as utils from "../../utils/Utils";
import pluralize from "pluralize";

import {SHORTDATE_FORMAT, DATETIME_FORMAT} from "../../constants";
import Branding from "../../config/Branding";

const CarouselItem = styled.div`
    display: table-cell;
    vertical-align: middle;
    border-radius: 3px;
    position: relative;
    text-align: center;
`;

const CarouselImage = styled(Image)`
    width: 95%;
    height: auto;
`;

const MessageContainer = styled.div`
    text-align: center;
    display: flex;
    justify-content: center;
`;

const ArrowButton = styled(Button)`
    margin: 0px 5px 5px 5px;
    padding: 5px;
    position: absolute;
    top: calc(50% - 35px);
    background: ${Branding.darkHighlyTransparentBackground};
    border-color: ${Branding.darkHighlyTransparentBackground};
`;

const ArrowLeftButton = styled(ArrowButton)`
    left: 0px;
`;

const ArrowRightButton = styled(ArrowButton)`
    right: 0px;
`;

const LiveIndicator = styled.div`
    position: absolute;
    top: 4px;
    left: 5px;
    z-index: 10;
`;

const EpisodePercentageViewed = styled.div`
    position: absolute;
    top: 2px;
    right: 5px;
    z-index: 10;
    background: ${Branding.darkTransparentBackground};
    color: white;
    border: ${Branding.darkTransparentBackground} solid thin;
    border-radius: 10px;
    padding: 0 3px;
    font-size: 12px;
    cursor: pointer;
`;

const BrandPercentageViewed = styled.div`
    position: absolute;
    top: 20px;
    right: 5px;
    z-index: 10;
    background: ${Branding.darkTransparentBackground};
    color: white;
    border: ${Branding.darkTransparentBackground} solid thin;
    border-radius: 10px;
    padding: 0 3px;
    font-size: 12px;
    cursor: pointer;
`;

const BrandViewCount = styled.div`
    position: absolute;
    top: 2px;
    right: 5px;
    z-index: 10;
    background: ${Branding.darkTransparentBackground};
    color: white;
    border: ${Branding.darkTransparentBackground} solid thin;
    border-radius: 10px;
    width: 30px;
    font-size: 12px;
    cursor: pointer;
`;

type HistoryCarouselProperties = {
    items: Array<Record<string, any>>,
    loading: boolean,
    loaded: boolean,
    brandView?: boolean
}

export default class HistoryCarousel extends React.Component<HistoryCarouselProperties> {

    private carouselElement: RefObject<AliceCarousel>;
    constructor(props: HistoryCarouselProperties) {
        super(props);

        this.carouselElement = React.createRef<AliceCarousel>();

        this.state = {
            items: [],
            loaded: false,
            loading: false
        };
    }

    private getPrevItem = (e: React.MouseEvent): void => {
        if(this.carouselElement.current) {
            const currentSlide = this.carouselElement.current.state.activeIndex;
            const itemsInSlide = this.carouselElement.current.state.itemsInSlide;
            let newIndex = currentSlide - itemsInSlide;
            if (newIndex <= 0) newIndex = 0;
            this.carouselElement.current.slideTo(newIndex);
        }
    }

    private getNextItem = (e: React.MouseEvent): void => {
        if(this.carouselElement.current) {
            const currentSlide = this.carouselElement.current.state.activeIndex;
            const itemsInSlide = this.carouselElement.current.state.itemsInSlide;
            const itemsTotal = this.carouselElement.current.state.itemsCount;
            let newIndex = currentSlide + itemsInSlide;
            if (newIndex >= itemsTotal - itemsInSlide) newIndex = itemsTotal - itemsInSlide;
            this.carouselElement.current.slideTo(newIndex);
        }
    }

    private getResponsiveSlideCount(): Record<number, any> {
        return {
            0:    { items: 7 },
            1400: { items: 9 },
            1650: { items: 11 },
            1920: { items: 13 },
            2400: { items: 15 },
            3840: { items: 17 }
        };
    }

    private getCarouselMaxItemCount(): number {
        const responsiveSlideCount = this.getResponsiveSlideCount();
        const windowWidth = window.innerWidth;
        const slideCountKeys = Object.keys(responsiveSlideCount);

        let i = 0;
        for (i = 0; i < slideCountKeys.length; i++) {
            if (parseInt(slideCountKeys[i]) > windowWidth && i > 0) {
                return responsiveSlideCount[parseInt(slideCountKeys[i-1])].items;
            }
        }
        if (i === slideCountKeys.length) return responsiveSlideCount[3800].items;
        return responsiveSlideCount[0].items;
    }

    private formatItemForCarousel(event: Record<string, any>, index: number): ReactNode {
        const item = event["thing"];
        const sameId = item["id"] === item["brandId"];
        const name = item["name"];


        let nameFragment = <small>{name}</small>;
        if (name.length >= 10 && name.indexOf(" ") === -1) {
            nameFragment = <small>{name.substring(0,10)}&hellip;</small>;
        }

        let viewCount = 0;
        if (event["count"]) {
            viewCount = event["count"];
        }

        let percentageWatchedTooltip = "Percentage watched";
        if (this.props.brandView) {
            percentageWatchedTooltip = "Highest episode watched percentage";
        }

        return (
            <>
                <LiveIndicator>{utils.isLiveItem(item)}</LiveIndicator>

                {this.props.brandView &&
                    <>
                        <OverlayTrigger
                            overlay={<Tooltip id="count-tooltip">{pluralize("view event", viewCount, true)}</Tooltip>}
                        >
                            <BrandViewCount>{viewCount}</BrandViewCount>
                        </OverlayTrigger>
                        {event["progressPct"] && <OverlayTrigger
                            overlay={<Tooltip id="count-tooltip">{percentageWatchedTooltip}</Tooltip>}
                        >
                            <BrandPercentageViewed>{event["progressPct"]}%</BrandPercentageViewed>
                        </OverlayTrigger>
                        }
                </>}
                {!this.props.brandView && event["progressPct"] && <OverlayTrigger
                    overlay={<Tooltip id="count-tooltip">{percentageWatchedTooltip}</Tooltip>}
                >
                    <EpisodePercentageViewed>{event["progressPct"]}%</EpisodePercentageViewed>
                </OverlayTrigger>}

                <OverlayTrigger
                    key={index}
                    placement={"auto"}
                    overlay={
                        <Popover id={`tooltip-${item["name"]}`}>
                            <Popover.Title as="h4">{item["name"]}</Popover.Title>
                            <Popover.Content>
                                {!sameId && <><b>ID</b>: {item["id"]}<br/></>}
                                <><b>Brand ID</b>: {item["brandId"]}<br/></>
                                {event["count"] && <>{event["count"]} views<br/></>}
                                <><b>Last viewed</b>: {moment(event["timestamp"]["initiated"]).format(DATETIME_FORMAT)}</>
                            </Popover.Content>
                        </Popover>
                    }
                >
                    <CarouselItem>
                        <CarouselImage
                            src={utils.getImageUrl(item)}
                            alt={item["id"]}
                            onError={utils.getErrorImage}
                            onDragStart={(event) => { event.preventDefault(); }}
                            key={index}
                            rounded
                        />
                        <br/>
                        <small>{moment(event["timestamp"]["initiated"]).format(SHORTDATE_FORMAT)}</small>
                        <br/>
                        {nameFragment}
                    </CarouselItem>
                </OverlayTrigger>
            </>
        );
    }

    public render(): ReactNode {

        const itemCount = this.getCarouselMaxItemCount();

        const items = this.props.items.map((event, i) => {
            return this.formatItemForCarousel(event, i);
        });

        while (items.length < itemCount) {
            items.push(<CarouselItem />);
        }

        if (this.props.items.length) {
            return (
                <>
                    <AliceCarousel
                        ref={this.carouselElement}
                        items={items}
                        disableButtonsControls
                        disableDotsControls
                        mouseTracking
                        responsive={this.getResponsiveSlideCount()}
                        paddingLeft={35}
                        paddingRight={35}
                    />

                    <ArrowLeftButton variant="secondary" onClick={this.getPrevItem}><Icons.CaretLeftFill /></ArrowLeftButton>
                    <ArrowRightButton variant="secondary" onClick={this.getNextItem}><Icons.CaretRightFill /></ArrowRightButton>
                </>
            );
        } else if (this.props.loading) {
            return <Spinner animation="border" variant="primary" />;
        } else if (this.props.loaded) {
            return (
                <MessageContainer>
                    No results
                </MessageContainer>
            );
        } else {
            return (
                <MessageContainer>
                </MessageContainer>
            );
        }
    }
}
