import React, { ReactNode, Component, ReactElement } from "react";
import { Col, Container, Row, InputGroup, OverlayTrigger, Tooltip, Form } from "react-bootstrap";
import styled from "styled-components";
import Branding from "../config/Branding";
import { getChartDomainInformation, getHostURL, makeSlotRequest } from "../utils/Requests";
import { addPortalUserInfoToUrl, convertToTitle, sortStringArrayNumerically } from "../utils/Utils";
import MetadataCarousel from "../components/MetadataCarousel";
import { CarouselRow, CentralCol } from "../components/SharedStyled";

const CustomerRow = styled(Row)`
    background: ${Branding.transparentBackground};
    top: ${(props) => (props.standalone ? "30px" : "63px")};
    margin-top: 10px;
`;

type ChartsViewerState = {
    chartInfo: Array<Record<string,any>>,
    mostPopularTopics: Array<string>,
    genreTopics: Array<string>,
    trendingTopics: Array<string>,
    otherTopics: Array<string>,
    chartTopics: Array<string>,
    selectedType: number,
    chartType: string,
    chartItems: Record<string, any>,
}

type ChartsViewerProperties = {
    groups: string[],
    standalone?: boolean,
    username: string
    customerConfig: CustomerConfig
}

export default class ChartsViewer extends Component<ChartsViewerProperties, ChartsViewerState> {

    MOST_POPULAR = "Most Popular";
    GENRE = "Genre";
    TRENDING = "Trending";
    OTHER = "Other";
    CHART_TYPES = [
        this.MOST_POPULAR, this.GENRE, this.TRENDING, this.OTHER
    ];

    constructor(props: ChartsViewerProperties) {
        super(props);

        this.state = {
            chartInfo: [],
            mostPopularTopics: [],
            genreTopics: [],
            trendingTopics: [],
            otherTopics: [],
            chartTopics: [],
            selectedType: 0,
            chartType: "Most Popular",
            chartItems: {}
        };
    }

    public componentDidMount(): void {
        this.reset();
    }

    private reset(): void {
        this.requestChartDomainInfo();
    }

    private async requestChartDomainInfo(): Promise<void> {
        await getChartDomainInformation(this.props.customerConfig).then((response: Array<Record<string, any>>) => {
            const chartTopics = response.map((chart) => {
                return chart["topic"];
            });

            const mostPopularTopics = sortStringArrayNumerically(chartTopics.filter((chartName) => {return chartName.indexOf("most-popular") === 0;}));
            const genreTopics = sortStringArrayNumerically(chartTopics.filter((chartName) => {return chartName.indexOf("genre") === 0;}));
            const trendingTopics = sortStringArrayNumerically(chartTopics.filter((chartName) => {return chartName.indexOf("trending") === 0;}));
            const otherTopics = sortStringArrayNumerically(chartTopics.filter((chartName) => {
                return chartName.indexOf("most-popular") !== 0 && chartName.indexOf("genre") !== 0 && chartName.indexOf("trending") !== 0;
            }));

            this.setState({
                chartInfo: response,
                mostPopularTopics,
                genreTopics,
                trendingTopics,
                otherTopics,
                chartTopics: mostPopularTopics
            }, this.requestCharts);
        });
    }

    private setChartType = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const selectedType: string = this.CHART_TYPES[parseInt(event.target.value)];
        let selectedTopics = this.state.mostPopularTopics;

        switch (selectedType) {
            case this.TRENDING:
                selectedTopics = this.state.trendingTopics;
                break;
            case this.GENRE:
                selectedTopics = this.state.genreTopics;
                break;
            case this.OTHER:
                selectedTopics = this.state.otherTopics;
                break;
            default:
                selectedTopics = this.state.mostPopularTopics;
                break;
        }

        this.setState({
            selectedType: parseInt(event.target.value),
            chartType: selectedType,
            chartTopics: selectedTopics
        }, this.requestCharts);
    }

    private requestCharts() {
        const requestURL = `${getHostURL(this.props.customerConfig)}/slots/chart/items`;

        this.state.chartTopics.forEach(async (topic) => {
            let chartUrl = `${requestURL}?chartName=${topic}`;
            let itemsRequest = null;

            // Make Request and store
            chartUrl = addPortalUserInfoToUrl(chartUrl, this.props.username, this.props.groups);

            itemsRequest = await makeSlotRequest(chartUrl, true);

            itemsRequest.json().then(async (responseJson) => {
                const chartItems = this.state.chartItems;
                chartItems[topic] = responseJson.items;

                this.setState({
                    chartItems
                });
            });
        });
    }

    private createCarouselRow(chartTopic: string): ReactElement {
        const title = convertToTitle(chartTopic);
        const items = this.state.chartItems[chartTopic];

        return (
            <CarouselRow key={chartTopic}>
                <Col md style={{ textAlign: "left" }}>
                    <h5 style={{ paddingLeft: "40px" }} className="mx-auto">{title}</h5>
                    {items &&
                        <MetadataCarousel items={items}
                            customer={this.props.customerConfig}
                            hideInfoIcon
                            modal
                            username={this.props.username}
                            groups={this.props.groups}
                        />
                    }
                </Col>
            </CarouselRow>
        );
    }

    private renderCharts() {
        const chartRails: ReactElement[] = [];

        this.state.chartTopics.forEach((chartTopic) => {
            chartRails.push(this.createCarouselRow(chartTopic));
        });

        return (
            <>
                {chartRails}
            </>
        );
    }

    public render(): ReactNode {
        return (
            <div>
                <Container className="mw-100" key="chart-viewer">
                    <CustomerRow className="mx-auto">
                        <Col md={3}>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <OverlayTrigger
                                        placement="left"
                                        overlay={<Tooltip id="preset-tooltip">Chart Type</Tooltip>}
                                    >
                                        <InputGroup.Text>Chart Type to display:</InputGroup.Text>
                                    </OverlayTrigger>
                                </InputGroup.Prepend>
                                <Form.Control
                                    as="select"
                                    id="dropdown-preset"
                                    title="Preset"
                                    onChange={this.setChartType}
                                    style={{ "padding": "0.375rem 0.375rem 0.375rem 0.1rem" }}
                                    value={this.state.selectedType}>
                                    {this.CHART_TYPES.map((value, i) => {
                                        return (
                                            <option value={i} key={i}>
                                                {value}
                                            </option>
                                        );
                                    })}

                                </Form.Control>
                            </InputGroup>
                        </Col>
                    </CustomerRow>
                    <hr/>
                    <Row className="justify-content-center">
                        <CentralCol md={11}>
                            {this.renderCharts()}
                        </CentralCol>
                    </Row>
                </Container>
            </div>
        );
    }
}
