import React, { ReactNode } from "react";
import {
    Button, ButtonGroup, Col, Container, Form, InputGroup,
    OverlayTrigger, Popover, Row, Tooltip
} from "react-bootstrap";
import {
    Gear as ConfigIcon,
    Trash as ClearIcon,
    FileEarmarkPlus as CreateIcon,
    FileEarmarkArrowDownFill as SaveIcon,
    Files as DuplicateIcon,
    InfoCircleFill as InfoIcon,
    ArrowClockwise as RefreshIcon
} from "react-bootstrap-icons";
import styled from "styled-components";

import AceEditor from "react-ace";
import "ace-builds/webpack-resolver";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-toml";
import "ace-builds/src-noconflict/theme-monokai";

import { getAllModelConfig, getModelConfig, postModelConfig } from "../utils/Requests";
import { generateGuid } from "../utils/Utils";
import SaveModelConfigModal from "../components/modelConfig/SaveModelConfigModal";
import Logger from "../utils/Logger";
import Branding from "../config/Branding";
import { AlertMidRight, FieldIcon, FieldNamePadded, FieldValue } from "../components/SharedStyled";

const RulesEditorCol = styled(Col)`
    background: ${Branding.superDarkBackground};
    text-align: center;
    border-right: thin white solid;
`;

const TrainingConfigCol = styled(Col)`
    background: ${Branding.superDarkBackground};
    text-align: center;
`;

const EditorButton = styled(Button)`
    margin-left: 5px;
`;

type ModelConfigEditorState = {
    customer: CustomerConfig,
    modelConfigs: Array<ModelConfig>,
    modelConfigId: string,
    modelConfigType: string,
    modelConfig?: ModelConfig,
    error: Error | null,
    modelConfigSaving: boolean,
    showSaveModal: boolean,
    create: boolean,
    showSuccess: boolean,
    showError: boolean,
    rulesMode: "toml" | "json",
    trainingConfigMode: "toml" | "json",
}

type ModelConfigEditorProperties = {
    standalone?: boolean,
    groups: string[],
    username: string,
    customerConfig: CustomerConfig
}

export default class ModelConfigEditor extends React.Component<ModelConfigEditorProperties, ModelConfigEditorState> {

    private newModelConfig: ModelConfig = {
        customer: "",
        modelType: "",
        pageId: "",
        version: "",
        rules: "{}",
        trainingConfig: "{}"
    }

    constructor(props: ModelConfigEditorProperties) {
        super(props);

        this.state = {
            customer: this.props.customerConfig,
            modelConfigId: "",
            modelConfigs: [],
            modelConfigType: "",
            error: null,
            showSaveModal: false,
            modelConfigSaving: true,
            create: false,
            showError: false,
            showSuccess: false,
            rulesMode: "json",
            trainingConfigMode: "json"
        };
    }

    public componentDidMount(): void {
        this.getModelConfigs();
    }

    private async getModelConfigs(): Promise<void> {
        const modelConfigs: Array<ModelConfig> = await getAllModelConfig(this.state.customer);

        let [modelConfig] = modelConfigs;
        let modelConfigId = `${modelConfig.modelType}-${modelConfig.version}`;

        const { slug: namespace } = this.state.customer;
        if (namespace === "epix") {
            modelConfigId = "epix-for_you-rfy_20230316_demo-rfy_20230316_demo";
            modelConfig = modelConfigs.find(config => `${config.modelType}-${config.version}` === modelConfigId) ?? modelConfig;
        }

        const trainingConfigMode: "json" | "toml" = modelConfig.trainingConfig[0] === "{" ? "json" : "toml";
        const rulesMode: "json" | "toml" = modelConfig.rules[0] === "{" ? "json" : "toml";

        this.setState({
            modelConfigs,
            modelConfig,
            modelConfigType: modelConfig.modelType,
            modelConfigId,
            modelConfigSaving: false,
            create: false,
            trainingConfigMode,
            rulesMode
        });
    }

    private setModelConfigId = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const modelConfigId = event.target.value;
        this.setModelConfigById(modelConfigId);
    };

    private setModelConfigById(modelConfigId: string) {
        const modelConfig = this.state.modelConfigs.find(config => `${config.modelType}-${config.version}` === modelConfigId) ?? {} as ModelConfig;

        const trainingConfigMode: "json" | "toml" = modelConfig.trainingConfig[0] === "{" ? "json" : "toml";
        const rulesMode: "json" | "toml" = modelConfig.rules[0] === "{" ? "json" : "toml";

        this.setState({
            modelConfig,
            modelConfigId,
            modelConfigType: modelConfig.modelType,
            modelConfigSaving: false,
            create: false,
            trainingConfigMode,
            rulesMode
        });
    }

    private createNewConfig = (): void => {
        const modelConfig = this.newModelConfig;
        modelConfig.customer = this.state.customer.slug;

        const trainingConfigMode: "json" | "toml" = modelConfig.trainingConfig[0] === "{" ? "json" : "toml";
        const rulesMode: "json" | "toml" = modelConfig.rules[0] === "{" ? "json" : "toml";

        this.setState({
            modelConfig,
            create: true,
            trainingConfigMode,
            rulesMode
        });
    };


    private duplicateConfig = (): void => {
        const modelConfig = JSON.parse(JSON.stringify(this.state.modelConfig));
        if (modelConfig) {
            const copyModelType = `${modelConfig.modelType} copy`;
            modelConfig.version = `${modelConfig.version} copy`;
            modelConfig.modelType = copyModelType;

            this.setState({
                modelConfig,
                modelConfigType: copyModelType,
                create: true
            });
        }
    };

    private saveModelConfig = async () => {
        this.setState({
            modelConfigSaving: true
        });

        const { customer, modelConfigType, modelConfig } = this.state;

        try {
            const response = await postModelConfig(
                customer,
                modelConfigType,
                modelConfig,
                modelConfig?.createdBy,
                modelConfig?.dateCreated
            );

            if (response && response.ok && response.status === 200) {
                setTimeout(() => {
                    this.setState({
                        showSuccess: true
                    });
                }, 500);
                setTimeout(() => {
                    this.setState({
                        showSuccess: false
                    }, this.getModelConfigs);
                }, 4000);
            } else {
                if (response) {
                    throw new Error(`Error during save, API response: ${response?.status} - ${response?.statusText}`);
                }
            }
        } catch (error) {
            const typedError = error as Error | Response;

            if (typedError instanceof Response) {
                typedError.json().then((responseJson) => {
                    const errorMessage = `Error during save, API response: ${typedError.status} - ${responseJson["message"]}`;
                    this.handleSaveError(new Error(errorMessage));
                });
            } else if (typedError instanceof Error) {
                this.handleSaveError(typedError);
            }
        }
    }

    private handleSaveError(error: Error) {
        Logger.error(error);
        this.setState({
            error,
            showError: true
        });
    }

    private discardChanges = async (): Promise<void> => {
        if (this.state.create) {
            this.getModelConfigs();
        } else {
            const modelConfig = await getModelConfig(this.state.customer, this.state.modelConfigType);
            this.setState({ modelConfig });
        }
    }

    private showSaveModal = (show: boolean): void => {
        this.setState({
            showSaveModal: show,
            modelConfigSaving: true
        });
    }

    private closeModal = (show: boolean): void => {
        this.setState({
            showSaveModal: false,
            modelConfigSaving: false
        });
    }

    private updateModelConfig = (updatedModelConfig: Partial<ModelConfig>): void => {
        this.setState((prevState: Readonly<ModelConfigEditorState>) => ({
            modelConfig: {
                ...prevState.modelConfig,
                ...updatedModelConfig
            } as ModelConfig
        }));
    }

    private setModelConfigType = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.updateModelConfig({ modelType: event.target.value });
    }

    private setModelConfigVersion = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.updateModelConfig({ version: event.target.value });
    }

    private setModelConfigPageId = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.updateModelConfig({ pageId: event.target.value });
    }

    private generatePageId = (): void => {
        this.updateModelConfig({ pageId: generateGuid() });
    }

    private setModelConfigRules = (value: string, event: any): void => {
        this.updateModelConfig({ rules: value });
    }

    private setModelConfigTrainingConfig = (value: string, event: any): void => {
        this.updateModelConfig({ trainingConfig: value });
    }

    private setRulesMode = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const rulesMode = event.target.value;
        if (rulesMode === "toml" || rulesMode === "json") {
            this.setState({
                rulesMode
            });
        }
    }

    private setTrainingConfigMode = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const trainingConfigMode = event.target.value;
        if (trainingConfigMode === "toml" || trainingConfigMode === "json") {
            this.setState({
                trainingConfigMode
            });
        }
    }

    private closeSuccess = (): void => {
        this.setState({ showSuccess: false });
    }

    private closeError = (): void => {
        this.setState({ showError: false });
    }

    public render(): ReactNode {

        const rules = this.state.modelConfig?.rules ?? "";
        const trainingConfig = this.state.modelConfig?.trainingConfig ?? "";

        const {
            showSaveModal,
            modelConfig,
            showSuccess,
            showError,
            error,
            modelConfigId,
            modelConfigs,
            create,
            rulesMode,
            trainingConfigMode
        } = this.state;

        return (
            <Container className="mw-100">
                <SaveModelConfigModal
                    closeCallback={this.closeModal}
                    saveCallback={this.saveModelConfig}
                    showModal={showSaveModal}
                    modelConfigVersion={modelConfig?.version}
                    modelConfigModelType={modelConfig?.modelType}
                />

                <AlertMidRight
                    variant='success'
                    show={showSuccess}
                    onClose={this.closeSuccess}
                    dismissible
                >
                    Model Config Saved
                </AlertMidRight>

                <AlertMidRight
                    variant='danger'
                    show={showError}
                    onClose={this.closeError}
                    dismissible
                >
                    {error?.message}
                </AlertMidRight>

                <Row style={{ marginTop: "5px", marginBottom: "5px", paddingBottom: "5px", borderBottom: "white solid thin" }}>
                    <Col md={4}>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <OverlayTrigger
                                    overlay={<Tooltip id="model-config-tooltip">Model Config</Tooltip>}
                                >
                                    <FieldIcon><ConfigIcon /></FieldIcon>
                                </OverlayTrigger>
                            </InputGroup.Prepend>
                            <Form.Control
                                as="select"
                                id="dropdown-model-config"
                                title="Model Config"
                                onChange={this.setModelConfigId}
                                style={{ "padding": "0.375rem", "height": "37px" }}
                                value={modelConfigId}
                                size="sm"
                                disabled={create}
                            >
                                {modelConfigs.sort((a, b) => {
                                    // ToDo: Is this how we want them sorted?
                                    return a.modelType.localeCompare(b.modelType) && a.version.localeCompare(b.version);
                                }).map((modelConfig) => {
                                    const label = `${modelConfig.modelType}-${modelConfig.version}`;
                                    return (
                                        <option value={label} key={label}>
                                            {modelConfig.modelType} ({modelConfig.version})
                                        </option>
                                    );
                                })}
                            </Form.Control>
                            <InputGroup.Append>
                                <OverlayTrigger
                                    overlay={<Popover id="popover-basic">
                                        <Popover.Content>
                                            {modelConfig?.dateCreated && <>
                                                <b>Created</b>: {modelConfig?.dateCreated} by {modelConfig?.createdBy}<br />
                                            </>}
                                            {modelConfig?.dateUpdated && <>
                                                <b>Updated</b>: {modelConfig?.dateUpdated} by {modelConfig?.lastUpdatedBy}<br />
                                            </>}
                                        </Popover.Content>
                                    </Popover>}
                                >
                                    <Button size="sm" variant="secondary"><InfoIcon /></Button>
                                </OverlayTrigger>
                            </InputGroup.Append>
                        </InputGroup>
                    </Col>
                    <Col md={2} className="d-flex justify-content-end align-items-center ml-auto">
                        <ButtonGroup>
                            <EditorButton size="sm" title="Create New Slot" onClick={this.createNewConfig}>
                                <CreateIcon style={{ fontSize: "25px" }} />
                            </EditorButton>
                            <EditorButton size="sm" title="Duplicate Slot" onClick={this.duplicateConfig}>
                                <DuplicateIcon style={{ fontSize: "25px" }} />
                            </EditorButton>
                            <EditorButton size="sm" title="Save Slot" onClick={() => { this.showSaveModal(true); }}>
                                <SaveIcon style={{ fontSize: "25px" }} />
                            </EditorButton>
                            <EditorButton variant="secondary" title="Discard Changes" size="sm" onClick={this.discardChanges}>
                                <ClearIcon style={{ fontSize: "25px" }} />
                            </EditorButton>
                        </ButtonGroup>
                    </Col>
                </Row>
                <Row style={{ marginTop: "5px", marginBottom: "5px" }}>
                    <Col md={4}>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <FieldNamePadded>Model Type</FieldNamePadded>
                            </InputGroup.Prepend>
                            <FieldValue
                                id="modelTypeInput"
                                type="string"
                                placeholder="Model Type"
                                value={modelConfig?.modelType}
                                onChange={this.setModelConfigType}
                                autoComplete={"off"}
                                size="sm"
                            />
                        </InputGroup>
                    </Col>
                    <Col md={4}>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <FieldNamePadded>Version</FieldNamePadded>
                            </InputGroup.Prepend>
                            <FieldValue
                                id="versionInput"
                                type="string"
                                placeholder="Version"
                                value={modelConfig?.version}
                                onChange={this.setModelConfigVersion}
                                autoComplete={"off"}
                                size="sm"
                            />
                        </InputGroup>
                    </Col>
                    {(modelConfig?.pageId || create) && <Col md={4}>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <FieldNamePadded>Page ID</FieldNamePadded>
                            </InputGroup.Prepend>
                            <FieldValue
                                id="pageIdInput"
                                type="string"
                                placeholder="Page ID"
                                value={modelConfig?.pageId}
                                onChange={this.setModelConfigPageId}
                                autoComplete={"off"}
                                size="sm"
                            />
                            <InputGroup.Append>
                                <OverlayTrigger
                                    placement="auto"
                                    overlay={<Tooltip id="refresh-tooltip">Generate New Page ID</Tooltip>}>
                                    <Button
                                        variant="primary"
                                        type="button"
                                        size="sm"
                                        onClick={() => { this.generatePageId(); }}
                                        disabled={!create}
                                    >
                                        <RefreshIcon />
                                    </Button>
                                </OverlayTrigger>
                            </InputGroup.Append>
                        </InputGroup>
                    </Col>}
                </Row>
                <Row>
                    <RulesEditorCol md={6}>

                        <Row style={{ paddingTop: "5px" }}>
                            <Col md={10}>
                                <big>Rules</big>
                            </Col>
                            <Col md={2}>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <FieldNamePadded>Mode</FieldNamePadded>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        as="select"
                                        id="dropdown-rules-mode"
                                        title="Rules Mode"
                                        onChange={this.setRulesMode}
                                        style={{ "padding": "0.375rem", "height": "37px" }}
                                        value={rulesMode}
                                        size="sm"
                                    >
                                        <option value="toml" key="toml">toml</option>
                                        <option value="json" key="json">json</option>
                                    </Form.Control>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: "5px", marginBottom: "5px", height: "72vh" }}>
                            <Col>
                                <AceEditor
                                    style={{ width: "100%", height: "100%" }}
                                    mode={rulesMode}
                                    theme="monokai"
                                    name="rulesEditor"
                                    editorProps={{ $blockScrolling: true }}
                                    setOptions={{
                                        useSoftTabs: true,
                                        tabSize: 2
                                    }}
                                    value={rules}
                                    onChange={this.setModelConfigRules}
                                    wrapEnabled
                                />
                            </Col>
                        </Row>
                    </RulesEditorCol>

                    <TrainingConfigCol md={6}>
                        <Row style={{ marginTop: "5px", marginBottom: "5px" }}>
                            <Col md={10}>
                                <big>Training Config</big>
                            </Col>
                            <Col md={2}>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <FieldNamePadded>Mode</FieldNamePadded>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        as="select"
                                        id="dropdown-training-config-mode"
                                        title="Training Config Mode"
                                        onChange={this.setTrainingConfigMode}
                                        style={{ "padding": "0.375rem", "height": "37px" }}
                                        value={trainingConfigMode}
                                        size="sm"
                                    >
                                        <option value="toml" key="toml">toml</option>
                                        <option value="json" key="json">json</option>
                                    </Form.Control>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: "5px", marginBottom: "5px", height: "72vh" }}>
                            <Col>
                                <AceEditor
                                    style={{ width: "100%", height: "100%" }}
                                    mode={trainingConfigMode}
                                    theme="monokai"
                                    name="trainingConfigEditor"
                                    editorProps={{ $blockScrolling: true }}
                                    setOptions={{
                                        useSoftTabs: true,
                                        tabSize: 2
                                    }}
                                    value={trainingConfig}
                                    onChange={this.setModelConfigTrainingConfig}
                                    wrapEnabled
                                />
                            </Col>
                        </Row>
                    </TrainingConfigCol>
                </Row>
            </Container>
        );
    }
}